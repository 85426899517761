import React from "react";
import PropTypes from "prop-types";
import { InView } from "react-intersection-observer";

import ChatData from "./HelperComponets/ChatData";
// CSS
import "../../../Styles/Theme/Theme.css";
import "./Styles/ChatDataContainer.css";

const ChatDataContainer = ({
  handlemoremessage = () => { },
  chatArray = [
    {
      variant: "Date",
      date: "19 Aug",
    },
    {
      variant: "GroupCreated",
      name: "Name",
      groupName: "Group Name",
    },
    {
      variant: "GroupLimit",
      memberCount: 5,
    },
    {
      variant: "UserJoinedGroup",
      name: "Name",
    },
    {
      variant: "UserLeaveGroup",
      name: "Name",
    },
    {
      variant: "ChannelCreated",
      name: "Name",
      channelName: "Channel Name",
    },
    {
      variant: "UserJoinedChannel",
      name: "Name",
    },
    {
      variant: "UserLeaveChannel",
      name: "Name",
    },
  ],
}) => {
  return (
    <div className="ChatDataContainer flex flex-col items-center ">
      {chatArray &&
        chatArray.map((chatData) => {
          // if (chatData.type !== "chatChannel") {
          if (chatData.isLastMessage) {
            return (
              <InView
                onChange={(inView, entry) => {
                  if (inView) {
                    handlemoremessage();
                  }
                }}
              >
                {" "}
                <ChatData
                  variant={chatData.variant}
                  name={chatData.name}
                  memberCount={chatData.memberCount}
                  date={chatData.date}
                  groupName={chatData.groupName}
                  channelName={chatData.channelName}
                  userName={chatData.userName}
                  oldName={chatData.oldName}
                  newName={chatData.newName}
                />{" "}
              </InView>
            );
          } else {
            return (
              <ChatData
                variant={chatData.variant}
                name={chatData.name}
                memberCount={chatData.memberCount}
                date={chatData.date}
                groupName={chatData.groupName}
                channelName={chatData.channelName}
                userName={chatData.userName}
                oldName={chatData.oldName}
                newName={chatData.newName}
              />
            );
          }
          // }
        })}
    </div>
  );
};
ChatDataContainer.propTypes = {
  chatArray: PropTypes.array,
};
export default ChatDataContainer;
