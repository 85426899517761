import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import moment from "moment";
import moment from "moment-timezone";
import momentTimeZone from "moment-timezone";

import Services from "../../../Services/auth.service.event";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import imgdelete from "../../../Images/BackendImages/delete.svg";
import editfill from "../../../Images/BackendImages/edit.svg";
import Loader from "../../../Images/loader.gif";
import copyimg from "../../../Images/file-copy-line 4.svg";
import hide from "../../../Images/Hide.svg";
import show from "../../../Images/Show.svg";
import { useDispatch } from "react-redux";
import CloneEventModal from "./CloneEventModal";
import { Tooltip } from "@mui/material";
import authService from "../../../Services/auth.service";
import ModalPopup from "../../ModalPopup/ModalPopup";
import SoldTicketEventModal from "./SoldTicketEventModal";
import PageViewEventModal from "./PageViewEventModal";
import { useGetAllEventCitiesQuery } from "../../../Services/event-services";

const predefinedOffsets = {
  "-720": "UTC-12", // International Date Line West
  "-660": "UTC-11", // Coordinated Universal Time-11
  "-600": "HST", // Hawaii Standard Time (UTC-10)
  "-570": "MIT", // Marquesas Islands Time (UTC-09:30)
  "-540": "AKST", // Alaska Standard Time (UTC-09)
  "-480": "PST", // Pacific Standard Time (UTC-08)
  "-420": "MST", // Mountain Standard Time (UTC-07)
  "-360": "CST", // Central Standard Time (UTC-06)
  "-300": "EST", // Eastern Standard Time (UTC-05)
  "-240": "AST", // Atlantic Standard Time (UTC-04)
  "-180": "NST", // Newfoundland Standard Time (UTC-03:30)
  "-120": "UTC-02", // Coordinated Universal Time-02
  "-60": "CVT", // Cape Verde Time (UTC-01)
  0: "UTC", // Coordinated Universal Time
  60: "CET", // Central European Time (UTC+01)
  120: "EET", // Eastern European Time (UTC+02)
  180: "MSK", // Moscow Standard Time (UTC+03)
  210: "IRST", // Iran Standard Time (UTC+03:30)
  240: "GST", // Gulf Standard Time (UTC+04)
  270: "AFT", // Afghanistan Time (UTC+04:30)
  300: "PKT", // Pakistan Standard Time (UTC+05)
  330: "IST", // India Standard Time (UTC+05:30)
  345: "NPT", // Nepal Time (UTC+05:45)
  360: "BST", // Bangladesh Standard Time (UTC+06)
  390: "MST", // Myanmar Standard Time (UTC+06:30)
  420: "ICT", // Indochina Time (UTC+07)
  480: "CST", // China Standard Time (UTC+08)
  525: "ACST", // Australian Central Standard Time (UTC+08:45)
  540: "JST", // Japan Standard Time (UTC+09)
  570: "ACST", // Australian Central Standard Time (UTC+09:30)
  600: "AEST", // Australian Eastern Standard Time (UTC+10)
  660: "NFT", // Norfolk Time (UTC+11)
  720: "NZST", // New Zealand Standard Time (UTC+12)
  765: "CHAST", // Chatham Standard Time (UTC+12:45)
  780: "NZDT", // New Zealand Daylight Time (UTC+13)
  840: "LINT", // Line Islands Time (UTC+14)
};

const columns = [
  { id: "title", label: "Name" },
  { id: "startDateAndTime", label: "Start date and time" },
  { id: "sold", label: "Sold tickets" },
  { id: "status", label: "Status" },
  { id: "eventAccess", label: "Access" },
  { id: "pageView", label: "Page view" },
  { id: "action", label: "Actions" },
];

//create a object of using given key
function createData(
  title,
  startDateAndTime,
  sold,
  eventAccess,
  action,
  status,
  ticketCount,
  pageView,
  pageViewList,
  isEventUrl,
  isCheckInAllow
) {
  return {
    title,
    startDateAndTime,
    sold,
    eventAccess,
    action,
    status,
    ticketCount,
    pageView,
    pageViewList,
    isEventUrl,
    isCheckInAllow,
  };
}
//Event list module
const ListEvents = () => {
  const navigate = useNavigate();
  const location = useLocation().search;
  const search = new URLSearchParams(location).get("search");
  const rowsFromUrl = new URLSearchParams(location).get("perpage");
  const tag = new URLSearchParams(location).get("tag");
  const locationType = new URLSearchParams(location).get("locationType");
  const city = new URLSearchParams(location).get("city");
  const otherFilter = new URLSearchParams(location).get("otherfilter");
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setShowalert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [cloning, setCloning] = useState(false);
  const [cloneSuccess, setCloneSuccess] = useState(false);
  const [cloneMsg, setCloneMsg] = useState("");
  const [totalData, setTotalData] = useState();
  const [selectedFilter, setFilter] = useState(
    otherFilter !== undefined && otherFilter !== null && otherFilter !== ""
      ? otherFilter
      : "ongoing"
  );
  //const [selectedFilter, setFilter] = useState('')
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedLocationType, setSelectedLocationType] = useState("");
  const [tagList, setTagList] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [actualsearch, setActualsearch] = useState("");
  const [listIndex, setListIndex] = useState(-1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [showSoldTicketEventModal, setShowSoldTicketEventModal] =
    useState(false);
  const [showPageViewModal, setShowPageViewModal] = useState(false);
  const [pageViewList, setPageViewList] = useState([]);
  const [eventId, setEventId] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const dispatch = useDispatch();

  const { data: getAllCities } = useGetAllEventCitiesQuery();
  useEffect(() => {
    setSearchText(search);
    tag !== null && setSelectedTag(tag);
    setFilter(
      otherFilter !== undefined && otherFilter !== null
        ? otherFilter
        : "ongoing"
    );
    if (otherFilter !== undefined && otherFilter !== null)
      eventListApi(
        search,
        otherFilter,
        tag !== null ? tag : selectedTag,
        locationType !== null ? locationType : selectedLocationType,
        page + 1,
        rowsFromUrl !== null ? rowsFromUrl : rowsPerPage,
        city !== null ? city : selectedCity
      );
    else
      eventListApi(
        search,
        selectedFilter,
        tag !== null ? tag : selectedTag,
        locationType !== null ? locationType : selectedLocationType,
        page + 1,
        rowsFromUrl !== null ? rowsFromUrl : rowsPerPage,
        city !== null ? city : selectedCity
      );
    setRowsPerPage(rowsFromUrl !== null ? rowsFromUrl : rowsPerPage);
    getTagList();
    getallusersSuggestionListApi();
    //setFilter(otherFilter !== undefined && otherFilter !== null ? otherFilter : "Upcoming Event")
  }, []);

  // tag list api function
  async function getTagList() {
    try {
      const tagListRes = await authService.getalltags_byadmin("");
      if (tagListRes.data.status) {
        setTagList(tagListRes.data.data);
      }
    } catch (error) {
      return false;
    }
  }
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getAllEventSuggestionList();
      let data = getSuggestionRes.data.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.title }));
        setSuggestionList(arr);
      }
    } catch (err) { }
  }

  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  //fetch all existing events list
  function eventListApi(
    searchText,
    selectedFilter,
    selectedTag,
    selectedLocationType,
    page,
    rowsPerPage,
    selectedCity,
    col,
    sort
  ) {
    setDataLoader(true);
    const encodedSearchText = encodeURIComponent(searchText); // for accept "+" sign
    let query = `?search=${encodedSearchText}&filter=${selectedFilter}&tag=${selectedTag}&locationTypeFilter=${selectedLocationType}&city=${selectedCity}`;
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    // ==========================
    // ==page===
    query +=
      page !== undefined && page !== null && page !== ""
        ? `&page=${page === 0 ? 1 : page}`
        : "";
    // ==rawperpage===
    query +=
      rowsPerPage !== undefined && rowsPerPage !== null && rowsPerPage !== ""
        ? `&limit=${rowsPerPage}`
        : "";
    // ===================
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    Services.getAllEvents(query)
      .then((res) => {
        if (res.data) {
          var data = res.data.data.allEventData;
          setTotalData(res.data.data.totalMessages);
          var table = [];
          let abbreviation;
          for (var i = 0; i < data.length; i++) {
            const startDateData = moment(data[i].startDate, "MM-DD-YYYY");
            const monthFormat =
              startDateData.format("MMMM").length > 3
                ? "MMM[.] DD, YYYY"
                : "MMM DD, YYYY";

            const timeZoneStr = data[i].timeZone;
            // Match the UTC offset from the string
            const match = timeZoneStr?.match(/UTC([+-]\d{2}:\d{2})/);

            // Proceed only if a match is found
            if (match) {
              const utcOffset = match[1];
              const [hoursStr, minutesStr] = utcOffset.split(":");
              const hours = parseInt(hoursStr, 10);
              const minutes = parseInt(minutesStr, 10);
              const sign = utcOffset[0];

              // Calculate the total offset in minutes
              const totalOffsetMinutes =
                (Math.abs(hours) * 60 + minutes) * (sign === "-" ? -1 : 1);

              abbreviation = predefinedOffsets[totalOffsetMinutes] || "";

              console.log("Detected Timezone Abbreviation:", abbreviation);
            } else {
              console.error("Invalid timeZone format");
            }

            table[i] = createData(
              data[i].title,
              data[i].startDate !== "" &&
              data[i].startDate !== "Invalid date" &&
              data[i].startTime !== "" &&
              data[i].startTime !== "Invalid date" && (
                <span>
                  {moment(data[i].startDate, "MM-DD-YYYY").format(
                    monthFormat
                  )}{" "}
                  at {moment(data[i].startTime, "hh:mm a").format("hh:mm A")}{" "}
                  {abbreviation}
                </span>
              ),
              `${data[i]?.sold_ticket ?? 0}/${data[i].quantity ?? 0}`,
              data[i].eventAccess,
              data[i]._id,
              data[i].status,
              data[i].mainTicketCount !== undefined ||
                data[i].mainTicketCount > 0
                ? data[i].mainTicketCount
                : 0,
              data[i]?.totalViewCount !== undefined ||
                data[i]?.totalViewCount > 0
                ? data[i].totalViewCount
                : 0,
              data[i]?.event_view_data?.length > 0
                ? data[i].event_view_data
                : [],
              data[i].locationType === "inPerson" && data[i].eventUrl !== ""
                ? true
                : false,
              data[i].isCheckInAllow ? true : false
            );
          }
          setRow(table);
          setAllEvents(table);
          setDataLoader(false);
        } else {
          setRow([]);
          setAllEvents([]);
          setDataLoader(false);
        }
      })
      .catch((e) => {
        setDataLoader(false);
        console.log(e);
      });
  }
  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    eventListApi(
      searchText,
      selectedFilter,
      selectedTag,
      selectedLocationType,
      newPage + 1,
      rowsPerPage,
      selectedCity
    );
  };
  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //search using text
  const handleSearch = (text) => {
    setSearchText(text);
    setSuggestionBox(false);
    setFilter("All Event");
    eventListApi(
      text,
      "All Event",
      selectedTag,
      selectedLocationType,
      1,
      rowsPerPage,
      selectedCity
    );
    navigate(`/events/list?search=${text}`);
  };

  //sorting function
  const handleSort = (col, direc) => {
    eventListApi(
      searchText,
      selectedFilter,
      selectedTag,
      selectedLocationType,
      page + 1,
      rowsPerPage,
      selectedCity,
      col,
      direc
    );
  };

  //delete event api call
  const handleDelete = () => {
    setDeleteLoader(true);
    Services.deleteEvent(deleteId)
      .then((res) => {
        if (res.data.status) {
          setShowDeleteModal(false);
          var table = [];
          var n = 0;
          for (var i = 0; i < allEvents.length; i++) {
            if (allEvents[i].action !== deleteId) {
              table[n] = createData(
                allEvents[i].title,
                allEvents[i].startDate,
                allEvents[i].sold,
                allEvents[i].eventAccess,
                allEvents[i].action,
                allEvents[i].status,
                allEvents[i].mainTicketCount !== undefined ||
                  allEvents[i].mainTicketCount > 0
                  ? allEvents[i].mainTicketCount
                  : 0,
                allEvents[i]?.totalViewCount !== undefined ||
                  allEvents[i]?.totalViewCount > 0
                  ? allEvents[i].totalViewCount
                  : 0,
                allEvents[i]?.event_view_data?.length > 0
                  ? allEvents[i].event_view_data
                  : [],
                allEvents[i].locationType === "inPerson" &&
                  allEvents[i].eventUrl !== ""
                  ? true
                  : false,
                allEvents[i].isCheckInAllow ? true : false
              );
              n++;
            }
          }
          setAllEvents(table);
          setRow(table);
          setDeleteLoader(false);
        }
        setShowalert(true);
        setAlertMsg(res.data.message);
        setTimeout(() => {
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // function for partner clone api
  const handleCloneEvent = async () => {
    setCloning(true);

    Services.cloneEvent({ eventId: deleteId }).then((resevent) => {
      if (resevent.status) {
        setCloning(false);
        setCloneSuccess(true);
        setPage(0);
        eventListApi(
          searchText,
          selectedFilter,
          selectedTag,
          selectedLocationType,
          1,
          rowsPerPage,
          selectedCity
        );
        setCloneMsg(resevent.data.message);
        setTimeout(() => {
          setCloneSuccess(false);
          setCloneMsg("");
        }, 3000);
      }
    });
  };

  //called when filter change
  const handleOnChangeFilterType = (e) => {
    setFilter(e.target.value);
    setPage(0);
    eventListApi(
      searchText,
      e.target.value,
      selectedTag,
      selectedLocationType,
      1,
      rowsPerPage,
      selectedCity
    );
  };

  //handle status update api function
  async function updateEventStatusApi(id, status) {
    setRow((prevData) =>
      prevData.map((item) => {
        if (item.action === id) {
          if (item.status === "published") {
            return { ...item, status: "paused" };
          } else {
            return { ...item, status: "published" };
          }
        }
        return item;
      })
    );
    let query = `${id}?status=${status}`;
    try {
      const updateRes = await Services.updateEventStatus(query);
      if (updateRes.data.status) {
        // getAllPartnerApi(page + 1, rowsPerPage)
      }
    } catch (error) {
      return false;
    }
  }

  // handle partner type filter change
  const handleOnChangeTag = (e) => {
    setSelectedTag(e.target.value);
    setDataLoader(true);
    eventListApi(
      searchText,
      selectedFilter,
      e.target.value,
      selectedLocationType,
      1,
      rowsPerPage,
      selectedCity
    );
    setPage(0)
  };

  //handle location type filter change
  const handleOnChangeLocationType = (e) => {
    setSelectedLocationType(e.target.value);
    setDataLoader(true);
    eventListApi(
      searchText,
      selectedFilter,
      selectedTag,
      e.target.value,
      1,
      rowsPerPage,
      selectedCity
    );
    setPage(0)
  };

  //handle location type filter change
  const handleOnChangeCity = (e) => {
    setSelectedCity(e.target.value);
    setDataLoader(true);
    eventListApi(
      searchText,
      selectedFilter,
      selectedTag,
      selectedLocationType,
      1,
      rowsPerPage,
      e.target.value
    );
    setPage(0)
  };

  //closing sold ticket popup while pressing esc
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27 && showSoldTicketEventModal) {
        setShowSoldTicketEventModal(false);
      }
      if (event.keyCode === 27 && showPageViewModal) {
        setShowPageViewModal(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showSoldTicketEventModal, showPageViewModal]);
  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>Events</h1>
            <div className="uic-fl-btn">
              <button
                className="btn"
                onClick={() => {
                  dispatch({ type: "PAGECHANGE", payload: false });
                  dispatch({
                    type: "NAVIGATEPAGE",
                    payload: `/events/list?search=${search}&otherfilter=${selectedFilter}&perpage=${rowsPerPage}`,
                  });
                  navigate("/events/createEvent");
                }}
              >
                Create event
              </button>
            </div>
          </div>

          <div className="uic-fl mt-0 d-flex flex-wrap gap-xl-4 gap-3 align-items-center mb-lg-4 mb-3">
            <div className="search-input position-relative mb-0 me-0 flex-grow-1">
              <input
                type="search"
                placeholder="Search"
                className="w-100"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (
                    suggestionList !== undefined &&
                    suggestionList.length > 0
                  ) {
                    setListIndex(-1);
                    setSuggestionBox(true);
                    var searchResult = suggestionList.filter((user) => {
                      return user.name
                        .toLowerCase()
                        .includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />

              {suggestionBox &&
                suggestions.length > 0 &&
                searchText.length > 0 && (
                  <div className="suggestion-box">
                    <ul id="suggestion-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleSearch(suggestion.name)}
                          className={
                            index === listIndex ? "selected-active" : ""
                          }
                        >
                          {searchText !== null &&
                            searchText.length > 0 &&
                            suggestion.name
                              .toLowerCase()
                              .startsWith(searchText.toLowerCase()) ? (
                            <>
                              <b>
                                {suggestion.name.slice(0, searchText.length)}
                              </b>
                              {suggestion.name &&
                                suggestion.name.slice(
                                  searchText.length,
                                  suggestion.name.length
                                )}{" "}
                            </>
                          ) : (
                            suggestion.name
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              ></i>
            </div>
            <div className="uic-fl-btn d-flex align-items-center flex-grow-1">
              <div className="w-100">
                <select
                  value={selectedFilter}
                  onChange={handleOnChangeFilterType}
                >
                  <option value="Upcoming Event">Upcoming events</option>
                  <option value="Draft">Draft</option>
                  <option value="Past Event">Past events</option>
                  <option value="All Event">All events</option>
                  <option value="ongoing">Live events</option>
                </select>
              </div>
            </div>
            <div className="uic-fl-btn d-flex align-items-center flex-grow-1">
              <div className="w-100">
                <select value={selectedTag} onChange={handleOnChangeTag}>
                  <option value="">Select Tag</option>
                  {tagList.map((tag) => (
                    <option value={tag._id}>{tag.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="uic-fl-btn d-flex align-items-center flex-grow-1">
              <div className="w-100">
                <select
                  value={selectedLocationType}
                  onChange={handleOnChangeLocationType}
                >
                  <option value="">Select location type</option>
                  <option value="inPerson">InPerson</option>
                  <option value="virtual">Virtual</option>
                  <option value="to_be_announce">To be announceed</option>
                </select>
              </div>
            </div>
            <div className="uic-fl-btn d-flex align-items-center flex-grow-1">
              <div className="w-100">
                <select value={selectedCity} onChange={handleOnChangeCity}>
                  <option value="">Select city</option>
                  {getAllCities?.data?.map((city) => (
                    <option value={city.city}>{city.city}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {showAlert && <div className="alert alert-info">{alertMsg}</div>}
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className="uic-th-td"
                      >
                        {column.id !== "action" && column.id !== "startTime" ? (
                          <>
                            <span className="custom-sorting">
                              <i
                                className="fa fa-angle-down"
                                onClick={(e) => {
                                  handleSort(column.id, "Dec");
                                }}
                              ></i>
                              <i
                                className="fa fa-angle-up"
                                onClick={(e) => {
                                  handleSort(column.id, "Asc");
                                }}
                              ></i>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="uic-table-td">
                  {dataLoader ? (
                    <div className="cstm-data-load">
                      <img src={Loader} />{" "}
                    </div>
                  ) : rows && rows.length > 0 ? (
                    rows.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                          className="uic-th-td"
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return column.id === "title" ||
                              column.id === "eventUrl" ? (
                              <TableCell
                                key={column.id}
                                className={`uic-th-td ${column.id === "title" ? "w-13" : "w-18"
                                  }`}
                              >
                                {column.id === "title" ? (
                                  <Link
                                    onClick={() => {
                                      dispatch({
                                        type: "PAGECHANGE",
                                        payload: false,
                                      });
                                      dispatch({
                                        type: "NAVIGATEPAGE",
                                        payload: `/events/list?search=${search}&otherfilter=${selectedFilter}&perpage=${rowsPerPage}`,
                                      });
                                      dispatch({
                                        type: "isCheckInAllow",
                                        payload: row["isCheckInAllow"],
                                      });
                                    }}
                                    to={`/events/detail?id=${row.action}`}
                                    className="cstm-view-users-count"
                                  >
                                    {value}
                                  </Link>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            ) : column.id === "sold" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-10"
                              >
                                <a
                                  onClick={() => {
                                    setShowSoldTicketEventModal(true);
                                    setEventId(row["action"]);
                                  }}
                                >
                                  {value}
                                </a>
                              </TableCell>
                            ) : column.id === "pageView" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-10"
                              >
                                <a
                                  onClick={() => {
                                    setShowPageViewModal(true);
                                    setPageViewList(row?.pageViewList);
                                  }}
                                >
                                  {value}
                                </a>
                              </TableCell>
                            ) : column.id === "status" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-10"
                              >
                                {value !== "draft" ? (
                                  <div className="partner-status-select">
                                    <a>
                                      {value === "paused" ? (
                                        <img src={hide} />
                                      ) : (
                                        <img src={show} />
                                      )}
                                    </a>
                                    <select
                                      className="status-select-only"
                                      disabled={value === "draft"}
                                      value={value}
                                      onChange={(e) =>
                                        updateEventStatusApi(
                                          row.action,
                                          e.target.value,
                                          row.ticketCount,
                                          row.isEventUrl
                                        )
                                      }
                                    >
                                      <option value="published">Live</option>
                                      <option value="paused">Pause</option>
                                    </select>
                                  </div>
                                ) : (
                                  <Link
                                    className="cstm-view-users-count"
                                    to={`/events/detail?id=${value}`}
                                  >
                                    Edit Draft
                                  </Link>
                                )}
                              </TableCell>
                            ) : column.id !== "action" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-10"
                              >
                                {value}
                              </TableCell>
                            ) : (
                              <TableCell
                                key={column.id}
                                className="uic-th-td uic-table-icon w-17"
                              >
                                <Tooltip title="Clone" arrow>
                                  <a
                                    data-toggle="modal"
                                    data-target="#cloneEvent"
                                    onClick={() => {
                                      setDeleteId(value);
                                    }}
                                  >
                                    <img src={copyimg} />
                                  </a>
                                </Tooltip>
                                <Tooltip title="Edit" arrow>
                                  <Link
                                    to={`/events/detail?id=${value}`}
                                    onClick={() => {
                                      dispatch({
                                        type: "PAGECHANGE",
                                        payload: false,
                                      });
                                      dispatch({
                                        type: "NAVIGATEPAGE",
                                        payload: `/events/list?search=${search}&otherfilter=${selectedFilter}&perpage=${rowsPerPage}`,
                                      });
                                      dispatch({
                                        type: "isCheckInAllow",
                                        payload: row["isCheckInAllow"],
                                      });
                                    }}
                                  >
                                    <img src={editfill} />
                                  </Link>
                                </Tooltip>
                                <Tooltip title="Delete" arrow>
                                  <a
                                    data-toggle="modal"
                                    data-target="#DeleteFromEvent"
                                    onClick={() => {
                                      setDeleteId(value);
                                      setShowDeleteModal(true);
                                      setDeleteName(row.title);
                                    }}
                                  >
                                    <img src={imgdelete} />
                                  </a>
                                </Tooltip>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <span className="no-data-found">No data found</span>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant="danger"
        buttonText1="Delete"
        isOpen={showDeleteModal}
        className=""
        handleClick2ndButton={handleDelete}
        hasLoading={deleteLoader}
        headingLable={"Delete event"}
      >
        {/* <div className="ctsm-modal-body">
          <p>lorrm dskasjgm asgkjnkdg sdg sdgindg sdgi</p>
        </div> */}
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this "{deleteName}"? This action
            cannot be undone.
          </p>
        </div>
      </ModalPopup>
      {cloning && (
        <div className="cstm-clone-msg-container">
          <h3>Cloning Record...</h3>
          <p>Please wait while the record is being cloned.</p>
        </div>
      )}
      {cloneSuccess && (
        <div className="cstm-success-msg-container">
          <div className="cstm-success-msg-container-title">{cloneMsg}</div>
        </div>
      )}
      {/* clone modal */}
      <CloneEventModal handleCloneEvent={handleCloneEvent} />
      {showSoldTicketEventModal && (
        <SoldTicketEventModal
          setShowSoldTicketEventModal={setShowSoldTicketEventModal}
          eventId={eventId}
        />
      )}
      {showPageViewModal && (
        <PageViewEventModal
          loader={dataLoader}
          pageViewList={pageViewList}
          setShowPageViewModal={setShowPageViewModal}
        />
      )}
    </>
  );
};
export default ListEvents;
