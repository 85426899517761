import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Services from "../../../Services/auth.service.event";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import SearchAndSorting from "../../AdminCommonComponent/TableComponent/SearchAndSorting";
import TableHeader from "../../AdminCommonComponent/TableComponent/TableHeader";
import TableBodyComponent from "../../AdminCommonComponent/TableComponent/TableBodyComponent";
import { useDropzone } from "react-dropzone";
import DeleteModalWithLoader from "../../AdminCommonComponent/DeleteModalWithLoader";
import MessageModal from "../../AdminCommonComponent/MessageModal";
import AlertComponent from "../../../Hooks/AlertComponent";
import ModalPopup from "../../ModalPopup/ModalPopup";


const columns = [
  { id: "name", label: "Name" },
  { id: "address", label: "Full address" },
  { id: "city", label: "City" },
  { id: "country", label: "Country" },
  { id: "action", label: "Actions" },
];

function createData(name, address, city, country, action) {
  return {
    name,
    address,
    city,
    country,
    action,
  };
}
const Locations = ({ tabInformation }) => {
  const emptyObject = {
    name: "",
    address: "",
    city: "",
    country: "",
    latitude: "",
    longitude: "",
    postalcode: "",
    showLocation: "",
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [addEvent, setAddEvent] = useState(emptyObject);
  const [addErr, setAddErr] = useState(emptyObject);
  const [editEvent, setEditEvent] = useState(emptyObject);
  const [editErr, setEditErr] = useState(emptyObject);
  const [selectedId, setSelectedId] = useState("");
  const [createInprocess, setcreateInprocess] = useState(false);
  const [editInprocess, setEditInprocess] = useState(false);
  const [address, setAddress] = useState("");
  const [showSuggestion, setshowSuggestion] = useState(false);
  const [placeId, setPlaceId] = useState("");
  const [editModalLoader, seteditModalLoader] = useState(false);
  const regex = /^[^0-9]+$/;
  const [loader, setLoader] = useState(false);
  const [imgFileList, setImgFileList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletionMessage, setDeletionMessage] = useState("");
  const [deleteModalLoader, setDeleteModalLoader] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [messageModalDivsArray, setMessageModalDivsArray] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const [clearSearch, setClearSearch] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [locationName, setLocationName] = useState("");

  //  image cropping state
  const [thumbnail, setThumbnail] = useState([]);
  const [modalOpen, setModalOpen] = useState();
  const [fileName, setFileName] = useState("");
  const [fileDimension, setFileDimension] = useState("");
  const [imgFileListData, setImgFileListData] = useState([]);
  const [imageErr, setImageErr] = useState("")
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(
    1 / 1
  );

  //Will call on dropping or setting file to dropzone
  const assignFiles = (acceptedFile) => {
    let tempArray = [...imgFileList, ...acceptedFile];
    // if (tempArray.length > 5) tempArray.length = 5;
    setImgFileList(tempArray)
  };
  // //Called when someone drop a file
  const onDrop = (acceptedFiles) => {
    // Check if any of the dropped files are not allowed
    const file = acceptedFiles[0];
    if (file && file.type.startsWith("image/")) {
      thumbnailImages(file);
      const reader = new FileReader();
      reader.onload = () => setThumbnail(reader.result);
      reader.readAsDataURL(file);
    }
    const notAllowedFiles = acceptedFiles.filter(
      (file) => !file.type.startsWith("image/")
    );
    if (notAllowedFiles.length > 0) {
      setImageErr("Invalid file type. Please select an image file.");
      return;
    }
    else {
      setImageErr("");
    }
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
    // inputRef: inputRef,
    getInputProps: (provided) => ({
      ...provided,
      accept: "image/png, image/gif, image/jpeg, image/jpg",
    }),
  });

  const removeSelectedImage = (index) => {
    setImgFileList(imgFileList.filter((_, i) => i !== index));
  };


  //get all location listing
  const getAllLocationApi = (
    isClearSearch,
    searchText,
    col,
    arr = "",
    sort
  ) => {
    let query = "";
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    Services.getAllEventLocationsByEventId(queryVariable, query)
      .then((res) => {
        setLoader(false);
        if (res.data.status) {
          var data = res.data.data;

          var table = [];
          for (var i = 0; i < data.length; i++) {
            table[i] = createData(
              data[i].name,
              data[i].address,
              data[i].city,
              data[i].country,
              data[i]._id
            );
          }

          setAllEvents(table);
          if (isClearSearch) {
            setClearSearch(true);
          }
          if (!isClearSearch && searchedText && searchedText.length > 0) {
            table = table.filter((loc) => {
              if (
                searchedText.length > 0 &&
                loc.name.toLowerCase().includes(searchedText.toLowerCase())
              )
                return loc;
            });
          }
          setRow(table);
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  useEffect(() => {
    if (tabInformation === "Location") {
      setLoader(true);
      setClearSearch(true);
      getAllLocationApi(true);
      //dispatch({type:"REMOVEMODALINPROCESS"})
      // handleClearAllStates()
    }
  }, [tabInformation]);

  //close modals
  const handleCloseAddModel = (e) => {
    setAddEvent(emptyObject);
    setAddErr(emptyObject);
    setImgFileList([]);
    setPlaceId("");
    setAddress("");
    handleClearAllStates();
  };

  //fetch data to edit and load on edit modal
  const handleEdit = (value) => {
    setSelectedId(value);
    seteditModalLoader(true);
    Services.getEventLocationById(value)
      .then((res) => {
        seteditModalLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          setEditEvent({
            name: data.name,
            address: data.address,
            latitude: data.latitude,
            longitude: data.longitude,
            postalcode: data.postalCode,
            city: data.city,
            country: data.country,
            action: data._id,
            showLocation: data.locationVisible ? "yes" : "no",
          });
          setImgFileList(data.locationImages);
          setAddress(data.address);
          setPlaceId(data.placeId);
        }
      })
      .catch((e) => {
        seteditModalLoader(false);
        console.log(e);
      });
  };
  //delete location api call
  const handleDelete = () => {
    setDeleteModalLoader(true);
    Services.deleteEventLocation(deleteId, queryVariable)
      .then((res) => {
        setDeleteModalLoader(false);
        setDeleteId("");
        setShowDeleteModal(false);
        if (res.data.status) {
          setShowAlert(true);
          setIsFailure(false);
          getAllLocationApi(true);
          setClearSearch(true);
          setMessageModalDivsArray([]);
          setAlertMsg(res.data.message);
          setTimeout(() => {
            setAlertMsg("");
            setShowAlert(false);
          }, 2000);
        } else {
          setIsFailure(true);
          let messageStringArray = [];
          if (res.data.data) {
            if (res.data.data.activityList.length) {
              let actList =
                "Activity List : " +
                res.data.data.activityList.toString().replace(",", ", ");
              messageStringArray.push(actList);
            }
            if (res.data.data.roomList.length) {
              let roomList =
                "Room List : " +
                res.data.data.roomList.toString().replace(",", ", ");
              messageStringArray.push(roomList);
            }
            setMessageModalDivsArray(messageStringArray);
          }
        }
        setDeletionMessage(res.data.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });

    setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
    if (e.target.name === "city") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "country") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "latitude") {
      if (e.target.value.length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "longitude") {
      if (e.target.value.length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "showLocation") {
      if (e.target.value.length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    }
  };

  //create location validation
  const handleCreate = async () => {
    var status = true;
    var addressErr = true;
    var temp = {};
    if (addEvent.name.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }
    if (addEvent.address.trim().length === 0) {
      temp = { ...temp, address: "Enter full address!" };
      addressErr = false;
      status = false;
    }
    if (!placeId && addressErr) {
      temp = {
        ...temp,
        address: "Please select full address from dropdown list!",
      };
      status = false;
    }
    if (addEvent.latitude.length === 0) {
      temp = { ...temp, latitude: "Enter latitude!" };
      status = false;
    }
    if (addEvent.longitude.length === 0) {
      temp = { ...temp, longitude: "Enter longitude!" };
      status = false;
    }
    if (addEvent.city.trim().length === 0) {
      temp = { ...temp, city: "Enter city!" };
      status = false;
    } else if (!regex.test(addEvent.city)) {
      temp = { ...temp, city: "Numbers are not allowed!" };
      status = false;
    }
    if (addEvent.country.trim().length === 0) {
      temp = { ...temp, country: "Enter country!" };
      status = false;
    } else if (!regex.test(addEvent.country)) {
      temp = { ...temp, country: "Numbers are not allowed!" };
      status = false;
    }
    if (!addEvent.showLocation) {
      temp = { ...temp, showLocation: "Select option!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setcreateInprocess(true);
      const formdata = new FormData();
      formdata.append("name", addEvent.name);
      formdata.append("address", addEvent.address);
      formdata.append("latitude", addEvent.latitude);
      formdata.append("longitude", addEvent.longitude);
      formdata.append("postalCode", addEvent.postalcode);
      formdata.append("city", addEvent.city);
      formdata.append("country", addEvent.country);
      formdata.append("placeId", placeId);
      formdata.append("event", queryVariable);
      formdata.append(
        "locationVisible",
        addEvent.showLocation === "yes" ? true : false
      );
      imgFileList.forEach((img) => {
        formdata.append("location_images", img);
      });

      Services.createEventLocation(formdata)
        .then((res) => {
          setcreateInprocess(false);
          if (res.data.status) {
            setShowAlertAdd(true);
            setAlertMsgAdd(res.data.message);
            setTimeout(() => {
              setShowAlertAdd(false);
              setAlertMsgAdd("");
            }, 3000);
            getAllLocationApi();
            setAddEvent(emptyObject);
            setImgFileList([]);
            handleClearAllStates();
            setClearSearch(true);
          } else {
            setShowAlertAdd(true);
            setAlertMsgAdd(res.data.message);
            setTimeout(() => {
              setShowAlertAdd(false);
              setAlertMsgAdd("");
            }, 3000);
            handleClearAllStates();
          }

          setPlaceId("");
          setAddress("");
          $("#AddLocation").modal("hide");
        })
        .catch((e) => {
          setcreateInprocess(false);
          handleClearAllStates();
          console.log(e);
        });
      setAddErr(emptyObject);
    } else {
      setAddErr(temp);
    }
  };
  //onChange event for edit location
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });

    setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    if (e.target.name === "city") {
      if (e.target.value && e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "country") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "latitude") {
      if (e.target.value.length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "longitude") {
      if (e.target.value.length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    }
  };

  //submit edited form
  const handleEditSubmit = async () => {

    var status = true;
    var temp = {};
    if (editEvent.name.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }
    if (editEvent.address.trim().length === 0) {
      temp = { ...temp, address: "Enter full address!" };
      status = false;
    } else if (!placeId) {
      temp = {
        ...temp,
        address: "Please select full address from dropdown list!",
      };
      status = false;
    }
    if (editEvent.latitude.length === 0) {
      temp = { ...temp, latitude: "Enter latitude!" };
      status = false;
    }
    if (editEvent.longitude.length === 0) {
      temp = { ...temp, longitude: "Enter longitude!" };
      status = false;
    }
    if (editEvent.city.trim().length === 0) {
      temp = { ...temp, city: "Enter city!" };
      status = false;
    }
    if (editEvent.country.trim().length === 0) {
      temp = { ...temp, country: "Enter country!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.querySelectorAll(
        `[name="${emptyKeys[0]}"]`
      );
      if (inputElement) {
        inputElement[1].focus(); // Focus the input element
      }
    }
    if (status) {
      setEditInprocess(true);
      const formdata = new FormData();
      formdata.append("name", editEvent.name);
      formdata.append("address", editEvent.address);
      formdata.append("latitude", editEvent.latitude);
      formdata.append("longitude", editEvent.longitude);
      formdata.append("postalCode", editEvent.postalcode);
      formdata.append("city", editEvent.city);
      formdata.append("country", editEvent.country);
      formdata.append("placeId", placeId);
      formdata.append(
        "locationVisible",
        editEvent.showLocation === "yes" ? true : false
      );
      imgFileList.forEach((img) => {
        if (typeof img === 'object') formdata.append("location_images", img);
        else formdata.append("locationOldData", img);
      });

      Services.editEventLocation({ data: formdata, id: selectedId })
        .then((res) => {
          if (res.data.status) {
            setShowAlertEdit(true);
            setAlertMsgEdit(res.data.message);
            setTimeout(() => {
              setShowAlertEdit(false);
              setAlertMsgEdit("");
            }, 3000);
            getAllLocationApi();
            setEditInprocess(false);
            $("#editModalLocation").modal("hide");
            setImgFileListData([])
          } else {
            setShowAlertEdit(true);
            setAlertMsgEdit(res.data.message);
            setTimeout(() => {
              setShowAlertEdit(false);
              setAlertMsgEdit("");
            }, 3000);
          }
          handleClearAllStates();
          setEditInprocess(false);
        })
        .catch((e) => {
          console.log(e);
          setEditInprocess(false);
          handleClearAllStates();
        });
    } else {
      setEditErr(temp);
    }
  };
  //onchange event for add location field
  const handleChangeAddLocation = (newAddress) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setAddress(newAddress);
    setshowSuggestion(true);
    setAddEvent({ ...addEvent, address: newAddress });
    setPlaceId("");
    if (newAddress) {
      setAddErr({ ...addErr, address: "" });
    }
  };
  //onchange event for edit location field
  const handleChangeEditLocation = (newAddress) => {
    setAddress(newAddress);
    setshowSuggestion(true);
    setEditEvent({ ...editEvent, address: newAddress });
    setPlaceId("");
    if (newAddress) {
      setEditErr({ ...editErr, address: "" });
    }
  };
  const getCityAndCountry = (results) => { };
  //called when user selects on suggestion from dropdown
  const handleSelectAddLocation = async (
    address,
    selectedPlaceId,
    selectedPlace
  ) => {
    setshowSuggestion(false);
    setPlaceId(selectedPlaceId);
    if (!selectedPlace?.description || !selectedPlace.description) return;
    setAddress(selectedPlace.description);
    //latitude, longitude api call
    var { latitude, longitude } = await getPlaceDetails(selectedPlaceId);
    let city = "";
    let country = "";
    //city, country api call
    const results = await geocodeByPlaceId(selectedPlaceId);
    for (const result of results) {
      for (const component of result.address_components) {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
        }
      }
    }
    if (selectedPlace) {
      setAddEvent({
        ...addEvent,
        address: selectedPlace.description,
        city: city,
        country: country,
        latitude,
        longitude,
      });
      let tempErrObj = addErr;
      if (city) {
        tempErrObj = { ...tempErrObj, city: "" };
      }
      if (country) {
        tempErrObj = { ...tempErrObj, country: "" };
      }
      if (latitude && longitude) {
        tempErrObj = { ...tempErrObj, latitude: "", longitude: "" };
      }
      setAddErr(tempErrObj);
    }
  };
  //called when user selects suggestion from dropdown  for edit modal
  const handleSelectEditLocation = async (
    address,
    selectedPlaceId,
    selectedPlace
  ) => {
    setshowSuggestion(false);
    setPlaceId(selectedPlaceId);
    if (!selectedPlace?.description || !selectedPlace.description) return;
    setAddress(selectedPlace.description);

    //latitude, longitude api call
    var { latitude, longitude } = await getPlaceDetails(selectedPlaceId);
    let city = "";
    let country = "";
    //city, country api call
    const results = await geocodeByPlaceId(selectedPlaceId);
    for (const result of results) {
      for (const component of result.address_components) {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
        }
      }
    }
    if (selectedPlace) {
      setEditEvent({
        ...editEvent,
        address: selectedPlace.description,
        city: city,
        country: country,
        latitude,
        longitude,
      });
      let tempErrObj = addErr;
      if (city) {
        tempErrObj = { ...tempErrObj, city: "" };
      }
      if (country) {
        tempErrObj = { ...tempErrObj, country: "" };
      }
      if (latitude && longitude) {
        tempErrObj = { ...tempErrObj, latitude: "", longitude: "" };
      }
      setEditErr(tempErrObj);
    }
  };
  //google map id and setup
  const googlePlacesAutocompleteProps = {
    apiKey: process.env.REACT_APP_GOOGLE_PLACE_KEY, // Replace with your actual API key
    debounce: 300,
  };

  const getPlaceDetails = async (placeId) => {
    const geocodeResponse = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_PLACE_KEY}`
    );
    const geocodeData = await geocodeResponse.json();

    if (geocodeData.status === "OK") {
      const { lat, lng } = geocodeData.results[0].geometry.location;
      return { latitude: lat, longitude: lng };
    } else {
      console.error("Geocode API request failed");
    }
  };

  //object from array
  const createObjectFromArray = (arr, updateAllEventArray = true) => {
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(
        arr[i].name,
        arr[i].address,
        arr[i].city,
        arr[i].country,
        arr[i].action
      );
    }
    setPage(0);
    setRow(tbl);
    if (updateAllEventArray) setAllEvents(tbl);
  };
  const handleDeleteModalCloseEvent = () => {
    setShowDeleteModal(false);
    setDeleteId("");
    setClearSearch(true);
    setSearchedText("");
  };

  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#AddLocation").hasClass("show")) {
        $("#AddLocation").modal("show");
      }

      if ($("#editModalLocation").hasClass("show")) {
        $("#editModalLocation").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#AddLocation").hasClass("show")) {
        $("#AddLocation").modal("hide");
      }

      if ($("#editModalLocation").hasClass("show")) {
        $("#editModalLocation").modal("hide");
      }
    }
  }, [stayOnPage]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //handle sorting
  const sortingHandler = (col, direc) => {
    const direcs = direc === "Dec" ? "Dec" : "Asc";
    getAllLocationApi("", "", col, "", direcs);
  };
  return (
    <>
      <SearchAndSorting
        currentArray={allEvents}
        createObjectFromArray={createObjectFromArray}
        sortingOn={"name"}
        searchOn={["name", "address"]}
        placeholderText={"Search location"}
        clearSearch={clearSearch}
        setClearSearch={setClearSearch}
        searchedText={searchedText}
        // recallApi={getAllLocationApi}
        setSearchedText={setSearchedText}
      >
        <div className="uic-title-rt-only mt-0 position-relative end-0 ms-auto">
          <div className="uic-fl-btn">
            <button
              className="btn"
              data-toggle="modal"
              data-target="#AddLocation"
              onClick={(e) => {
                handleCloseAddModel(e);
              }}
            >
              Create Location
            </button>
          </div>
        </div>
      </SearchAndSorting>

      {showAlert && <div className="alert alert-info">{alertMsg}</div>}
      <Paper className="uic-tb-mn mt-lg-4 mt-3">
        <TableContainer className="uic-table-main">
          <Table className="uic-table">
            <TableHeader
              columns={columns}
              createObjectFromArray={createObjectFromArray}
              allEventList={allEvents}
              columnsWithoutSoritng={["action"]}
              // listApi={getAllLocationApi}
              setLoader={setLoader}
              searchText={""}
              sortingHandler={sortingHandler}
            />
            {loader ? (
              <div className="cstm-data-load">
                <img src={Loader} />{" "}
              </div>
            ) : rows.length > 0 ? (
              <TableBodyComponent
                rows={rows}
                rowsPerPage={rowsPerPage}
                page={page}
                columns={columns}
                editCallBack={(value) => handleEdit(value)}
                setDeleteId={(id, row) => {
                  setDeleteId(id);
                  setShowDeleteModal(true);
                  setLocationName(row.name);
                }}
                editModalId="editModalLocation"
                deleteModalId=""
              />
            ) : (
              <span className="no-data-found">No data found</span>
            )}
          </Table>
        </TableContainer>
      </Paper>
      {/* //create modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="AddLocation"
        tabIndex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create Location</h2>
              <button
                type="button"
                className="close"
                // data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#AddLocation").modal("hide");
                    handleCloseAddModel(e);
                    setImageErr("")
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={handleAddChange}
                    value={addEvent.name}
                  />
                </div>
                <span className="error">{addErr.name}</span>
              </div>
              <div className="form-group">
                <div className="form-group-label">Full address*</div>
                <div className="form-group-ct">
                  <PlacesAutocomplete
                    value={address}
                    onSelect={handleSelectAddLocation}
                    onChange={handleChangeAddLocation}
                    {...googlePlacesAutocompleteProps}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Enter a location",
                          })}
                          value={address} // Pass the value prop directly to the input element
                        />
                        <div>
                          <div className="location-suggestions">
                            {loading ? (
                              <div class="location-suggestions-loading">
                                <img src={Loader} />
                              </div>
                            ) : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#e8e8e8"
                                  : "#ffffff",
                              };
                              if (showSuggestion) {
                                return (
                                  <div
                                    className="location-suggestions-in"
                                    tabIndex="-1"
                                    key={suggestion.placeId}
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              }
                              return "";
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <span className="error">{addErr.address}</span>
              </div>
              <div className="form-group">
                <div className="form-group-label">Latitude*</div>
                <div className="form-group-ct">
                  <input
                    type="number"
                    placeholder="Enter latitude"
                    name="latitude"
                    onChange={handleAddChange}
                    value={addEvent.latitude}
                  />
                </div>
                <span className="error">{addErr.latitude}</span>
              </div>
              <div className="form-group">
                <div className="form-group-label">Longitude*</div>
                <div className="form-group-ct">
                  <input
                    type="number"
                    placeholder="Enter longitude"
                    name="longitude"
                    onChange={handleAddChange}
                    value={addEvent.longitude}
                  />
                </div>
                <span className="error">{addErr.longitude}</span>
              </div>
              <div className="form-group">
                <div className="form-group-label">Postal code</div>
                <div className="form-group-ct">
                  <input
                    type="number"
                    placeholder="Enter postal code"
                    name="postalcode"
                    onChange={handleAddChange}
                    value={addEvent.postalcode}
                  />
                </div>
                <span className="error">{addErr.postalcode}</span>
              </div>
              <div className="form-group">
                <div className="form-group-label">City*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter city name"
                    name="city"
                    onChange={handleAddChange}
                    value={addEvent.city}
                  />
                </div>
                <span className="error">{addErr.city}</span>
              </div>
              <div className="form-group">
                <div className="form-group-label">Country*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter country name"
                    name="country"
                    onChange={handleAddChange}
                    value={addEvent.country}
                  />
                  <span className="error">{addErr.country}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="cstm-channel-type-title">
                  Do you want to show this location in front side application?*
                </div>
                <div className="form-group-ct">
                  <label className="subcat-addvideo">
                    <input
                      type="radio"
                      id="yes"
                      name="showLocation"
                      value="yes"
                      onChange={handleAddChange}
                      defaultChecked={addEvent.showLocation === "yes"}
                      checked={addEvent.showLocation === "yes"}
                    />
                    Yes
                  </label>
                  <label className="subcat-addvideo">
                    <input
                      type="radio"
                      id="no"
                      name="showLocation"
                      value="no"
                      onChange={handleAddChange}
                      defaultChecked={addEvent.showLocation === "no"}
                      checked={addEvent.showLocation === "no"}
                    />
                    No
                  </label>
                  <span className="error">{addErr.showLocation}</span>
                </div>
              </div>
              <div
                {...getRootProps()}
                className="edit-group-info-mn-change-img-label"
              >
                <input {...getInputProps()} />

                <div className="cstm-apl-main">Click here to add photos</div>
              </div>
              <div className="form-group">
                <div className="cstm-apl-upload-img">
                  {imgFileList.map((image, index) => {
                    return (
                      <>
                        <div className="cstm-apl-upload-img-box">
                          <span
                            className="cstm-apl-upload-close"
                            onClick={() => {
                              removeSelectedImage(index);
                            }}
                          >
                            ×
                          </span>
                          <img
                            alt="location image"
                            src={typeof image === 'object' ? URL.createObjectURL(image) : ""}

                          />
                        </div>
                      </>
                    );
                  })}
                </div>
                <span className="error">{imageErr}</span>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* //edit modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editModalLocation"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit Location</h2>
                <button
                  type="button"
                  className="close"
                  // data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                    } else {
                      $("#editModalLocation").modal("hide");
                      handleCloseAddModel(e);
                      setImageErr("")
                    }
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      onChange={handleEditChange}
                      value={editEvent.name}
                    />
                  </div>
                  <span className="error">{editErr.name}</span>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Full address*</div>
                  <div className="form-group-ct">
                    <PlacesAutocomplete
                      name="address"
                      value={address}
                      onChange={handleChangeEditLocation}
                      onSelect={handleSelectEditLocation}
                      {...googlePlacesAutocompleteProps}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Enter a location",
                            })}
                            value={address} // Pass the value prop directly to the input element
                          />
                          <div className="location-suggestions">
                            {loading ? (
                              <div class="location-suggestions-loading">
                                <img src={Loader} />
                              </div>
                            ) : null}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#e8e8e8"
                                  : "#ffffff",
                              };
                              if (showSuggestion) {
                                return (
                                  <div
                                    className="location-suggestions-in"
                                    key={suggestion.placeId}
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              }
                              return "";
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <span className="error">{editErr.address}</span>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Latitude*</div>
                  <div className="form-group-ct">
                    <input
                      type="number"
                      placeholder="Enter latitude"
                      name="latitude"
                      onChange={handleEditChange}
                      value={editEvent.latitude}
                    />
                  </div>
                  <span className="error">{editErr.latitude}</span>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Longitude*</div>
                  <div className="form-group-ct">
                    <input
                      type="number"
                      placeholder="Enter longitude"
                      name="longitude"
                      onChange={handleEditChange}
                      value={editEvent.longitude}
                    />
                  </div>
                  <span className="error">{editErr.longitude}</span>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Postal code</div>
                  <div className="form-group-ct">
                    <input
                      type="number"
                      placeholder="Enter postal code"
                      name="postalcode"
                      onChange={handleEditChange}
                      value={editEvent.postalcode}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">City*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter city name"
                      name="city"
                      onChange={handleEditChange}
                      value={editEvent.city}
                    />
                  </div>
                  <span className="error">{editErr.city}</span>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Country*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter country name"
                      name="country"
                      onChange={handleEditChange}
                      value={editEvent.country}
                    />
                  </div>
                  <span className="error">{editErr.country}</span>
                </div>
                <div className="form-group">
                  <div className="cstm-channel-type-title">
                    Do you want to show this location in front side
                    application?*
                  </div>
                  <div className="form-group-ct">
                    <label className="subcat-addvideo">
                      <input
                        type="radio"
                        id="yes"
                        name="showLocation"
                        value="yes"
                        onChange={handleEditChange}
                        defaultChecked={editEvent.showLocation === "yes"}
                        checked={editEvent.showLocation === "yes"}
                      />
                      Yes
                    </label>
                    <label className="subcat-addvideo">
                      <input
                        type="radio"
                        id="no"
                        name="showLocation"
                        value="no"
                        onChange={handleEditChange}
                        defaultChecked={editEvent.showLocation === "no"}
                        checked={editEvent.showLocation === "no"}
                      />
                      No
                    </label>
                    <span className="error">{editErr.showLocation}</span>
                  </div>
                </div>
                <div
                  {...getRootProps()}
                  className="edit-group-info-mn-change-img-label"
                >
                  <input {...getInputProps()} />

                  <div className="cstm-apl-main">Click here to add photos</div>
                </div>
                <span className="error">{imageErr}</span>
                <div className="form-group">
                  <div className="cstm-apl-upload-img">
                    {imgFileList.map((image, index) => {
                      return (
                        <>
                          <div className="cstm-apl-upload-img-box">
                            <span
                              className="cstm-apl-upload-close"
                              onClick={() => {
                                removeSelectedImage(index);
                              }}
                            >
                              ×
                            </span>
                            <img
                              alt="location image"
                              src={typeof image === 'object' ? URL.createObjectURL(image) : image}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="edit-user-details-footer cstm-create-button">
                {editInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      {/* Modal Delete */}

      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant={!deleteModalLoader ? "danger" : "secondary"}
        buttonText1={
          !deleteModalLoader ? (
            "Delete"
          ) : (
            <img
              src={Loader2}
              width={50}
              height={15}
              className="btn-loader-img-cover"
            />
          )
        }
        isOpen={showDeleteModal}
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete location"}
      >
        {/* <div className="ctsm-modal-body">
          <p>lorrm dskasjgm asgkjnkdg sdg sdgindg sdgi</p>
        </div> */}
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this "{locationName}"? This action
            cannot be undone.
          </p>
        </div>
      </ModalPopup>
      {/* {showDeleteModal && (
        <DeleteModalWithLoader
          message={"Are you sure you want to delete this location?"}
          handleDelete={handleDelete}
          onClose={handleDeleteModalCloseEvent}
          loader={deleteModalLoader}
        />
      )} */}

      <MessageModal
        deletionMessage={deletionMessage}
        setDeletionMessage={setDeletionMessage}
        failure={isFailure}
        additionalDivArray={messageModalDivsArray}
      />
      <AlertComponent />
    </>
  );
};
export default Locations;
