import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import "./Styles/ChatSideBar.css";
import PropTypes from "prop-types";
import "../../../Styles/Theme/Theme.css";
import "../../../Styles/frontendGlobal.css";
import SearchField from "../../../components/InputField/SearchField";
import TabsFluid from "../../../components/Tabs/TabsFluid";
import EmptyState from "../../../components/EmptyState/EmptyState";
import ChatFloatingButtonPrimary from "../Components/HelperComponets/ChatFloatingButtonPrimary";
import ChatCell from "../Components/ChatCell";
import ChatAccordion from "../Components/HelperComponets/ChatAccordion";
import { replaceIdWithNameUsingUnformattedList } from "../CommonFunctions/taggedUserIdReplace.js";
import IconSearchNoResults from "../../../components/GlobalComponents/Icons/IconEmptyState/IconSearchNoResults.js";
import Spinners from "../../../components/Spinners/Spinners.js";

const ChatSideBar = ({
  hasAccordions = false,
  hasFloatingButton = false,
  handleClick,
  chatType = "Personal",
  groupLable = "W",
  hasText = true,
  time = "Yesterday",
  name = "Name",
  src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  chatCellHasCounter = true,
  messageType = "text",
  muteStatus = "NotMuted",
  senderName = "nickname",
  messageCount = "2",
  hasMentions = false,
  hasSender = false,
  handleChatListClick,
  accordionLable = "Starred",
  accordionLable1 = "MDS channels",
  isCollapsed = true,
  isCollapsed1 = true,
  label = "Channels",
  counter = "3",
  hasCounterTabs = false,
  hasBadge = true,
  label1 = "Messages",
  counter1 = "5",
  hasCounterTabs1 = false,
  hasBadge1 = true,
  isOnline = false,
  hasStatus = true,
  settext,
  handleContextMenu,
  setdeleteConversationPopup,
  handleStarUser,
  setDisplaySearch,
  contextMenuId,
  setcontextMenuId,
  quote_btn_start,
  setquote_btn_start,
  quote_btn,
  setquotebtn,
  contextMenuChatType,
  setcontextMenuChatType,
  contextmenu,
  setcontextmenu,
  setsidebar,
  openChatListModel,
  openModel,
  muteNotificationList,
  handleChatListClickFunc,
  chatList,
  starchatuser,
  starChatListChannel,
  starChatListMessages,
  unstarChatListChannel,
  unstarChatListMessages,
  activityMessageDisplay,
  handleSearchBar,
  searchInput,
  handleClear,
  countChatMessage,
  countChatChannel,
  setContextMenuName,
}) => {
  const { userid, type } = useParams();
  const [isChannelSelected, setIsChannelSelected] = useState(false);
  const [isMessageSelected, setIsMessageSelected] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [collapsed1, setCollapsed1] = useState(true);

  const myId = localStorage.getItem("userid");
  const isStarred = localStorage.getItem("isStarredMessages") || "true";
  const isStarredChannel = localStorage.getItem("isStarredChannel") || "true";
  const isMessage = localStorage.getItem("isMessage") || "true";
  const isChannel = localStorage.getItem("isChannel") || "true";

  // get Chat All Users data
  const chatAllUsers = useSelector((state) => {
    return state.ReduAllUsersList;
  });

  // get sidebar loader
  const sidebarLoader = useSelector((state) => {
    return state.chatSidebarLoader;
  });

  // set collapes state
  useEffect(() => {
    if (starChatListChannel.length === 0 || starChatListMessages.length === 0) {
      if (!isChannelSelected) {
        setCollapsed1(true);
      } else {
        setCollapsed1(true);
      }
    }

    if (!isChannelSelected) {
      setCollapsed1(isMessage === "true");
      setCollapsed(isStarred === "true");
    } else {
      setCollapsed1(isChannel === "true");
      setCollapsed(isStarredChannel === "true");
    }
  }, [
    isStarred,
    isMessage,
    isChannel,
    isStarredChannel,
    starChatListChannel,
    starChatListMessages,
  ]);

  // set selected tabs
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get('tab');
    switch (tab) {
      case 'channel':
        setIsChannelSelected(true);
        setIsMessageSelected(false);
        break;
      case 'message':
        setIsChannelSelected(false);
        setIsMessageSelected(true);
        break;
      default:
        if (type === 'chatChannel') {
          setIsChannelSelected(true);
          setIsMessageSelected(false);
        } else {
          setIsChannelSelected(false);
          setIsMessageSelected(true);
        }
        break;
    }
  }, [type,window.location.search]);

  // handle tab click to change
  const handleTabClick = (tabIndex) => {
    if (tabIndex === 0) {
      setIsChannelSelected(true);
      setIsMessageSelected(false);
      setCollapsed1(isChannel === "true");
      setCollapsed(isStarredChannel === "true");
    } else if (tabIndex === 1) {
      setIsChannelSelected(false);
      setIsMessageSelected(true);
      setCollapsed1(isMessage === "true");
      setCollapsed(isStarred === "true");
    }
  };

  // handle toggle channels and message
  const handleToggleChannels = () => {
    setCollapsed(!collapsed); // Toggle the state
    const updatedStarredStatus = !collapsed;
    if (!isChannelSelected) {
      localStorage.setItem("isStarredMessages", updatedStarredStatus);
    } else {
      localStorage.setItem("isStarredChannel", updatedStarredStatus);
    }
  };

  // handle toggle starred
  const handleToggleStarred = () => {
    setCollapsed1(!collapsed1); // Toggle the state
    const updatedStarredStatus = !collapsed1;
    if (!isChannelSelected) {
      localStorage.setItem("isMessage", updatedStarredStatus);
    } else {
      localStorage.setItem("isChannel", updatedStarredStatus);
    }
  };

  return (
    <div className="chat-sidebar" onClick={handleClick}>
      <div className="chat-sidebar-head">
        <div className="chat-sidebar-head-search">
          <SearchField
            className="text-field-search"
            handleChange={(e) => handleSearchBar(e)}
            readOnly={false}
            handleKeyUp={() => { }}
            helperText=""
            isRounded
            label="Label"
            name="search"
            value={searchInput}
            handleClear={handleClear}
          />
        </div>
        <div className="chat-sidebar-head-tabs">
          <TabsFluid
            isSelected={isChannelSelected}
            isSelected1={isMessageSelected}
            label="Channels"
            label1="Messages"
            counter="0"
            counter1="0"
            hasBadge={countChatChannel > 0}
            hasBadge1={countChatMessage > 0}
            hasCounterTabs={false}
            hasCounterTabs1={false}
            handleClick={handleTabClick}
          />
        </div>
        <hr />
      </div>
      <>
        {/* add a loader */}
        {sidebarLoader && chatAllUsers.length === 0 ? (
          <div className="chat-sidebar-loading">
            <Spinners size="32" type="brand" />
          </div>
        ) : isChannelSelected &&
          !starChatListChannel &&
          !unstarChatListChannel ? (
          <div className="chat-sidebar-empty-state">
            <EmptyState
              isClear={false}
              subTitle={
                searchInput ? "No search results" : "New channels on the way."
              }
              title={!searchInput ? "All clear for now!" : ""}
              icon={searchInput && <IconSearchNoResults />}
              hasTitle={!searchInput ? true : false}
              hasIcon={searchInput ? true : false}
            />
          </div>
        ) : isMessageSelected &&
          !starChatListMessages &&
          !unstarChatListMessages ? (
          <div className="chat-sidebar-empty-state">
            <EmptyState
              isClear={false}
              title={!searchInput ? "It`s quiet here." : ""}
              subTitle={
                searchInput ? "No search results" : "Start a conversation!"
              }
              icon={searchInput && <IconSearchNoResults />}
              hasTitle={!searchInput ? true : false}
              hasIcon={searchInput ? true : false}
            />
          </div>
        ) : (
          <div className="chat-sidebar-body scrollbar">
            <div className="chat-sidebar-body-list">
              {starChatListChannel.length > 0 ||
                starChatListMessages.length > 0 ? (
                <>
                  {hasAccordions &&
                    ((isChannelSelected && starChatListChannel.length) ||
                      (isMessageSelected && starChatListMessages.length)) ? (
                    <>
                      <div className="chat-sidebar-body-container">
                        <ChatAccordion
                          isCollapsed={collapsed}
                          name="Starred"
                          handleToggle={handleToggleChannels}
                        />
                        {collapsed ? (
                          isMessageSelected ? (
                            <>
                              {starChatListMessages.map((chat, index) => {
                                let onlineStatus =
                                  chat &&
                                  chatAllUsers &&
                                  chatAllUsers.filter(
                                    (user) =>
                                      user?.id === chat?.receiverId?._id &&
                                      user?.onlineStatus
                                  ).length;
                                const listDate = chat.userTimeStamp
                                  ? moment(
                                    moment.utc(chat.userTimeStamp).toDate()
                                  ).format("MMMM D, YYYY")
                                  : chat.createdAt && chat.createdAt.length
                                    ? moment(chat.createdAt).format(
                                      "MMMM D, YYYY"
                                    )
                                    : "";
                                const listTime = chat.userTimeStamp
                                  ? moment(
                                    moment.utc(chat.userTimeStamp).toDate()
                                  ).format("hh:mm A")
                                  : chat.createdAt && chat.createdAt.length
                                    ? moment(chat.createdAt).format("hh:mm A")
                                    : "";
                                const sendTime =
                                  listDate &&
                                    listDate.length &&
                                    listDate === moment().format("MMMM D, YYYY")
                                    ? listTime
                                    : listDate &&
                                      listDate.length &&
                                      moment().diff(listDate, "days") === 1
                                      ? "Yesterday"
                                      : listDate &&
                                        moment().diff(listDate, "days") < 7
                                        ? moment(listDate).format("dddd")
                                        : listDate &&
                                          moment().diff(listDate, "months") === 1
                                          ? moment(listDate).format("D MMM")
                                          : listDate && listDate.length > 0
                                            ? moment(listDate).format("DD.MM.YY")
                                            : listTime;
                                let profileImage =
                                  chat?.image &&
                                    chat?.image.split("?")[0].split("/")[
                                      chat?.image.split("?")[0].split("/")
                                        .length - 1
                                    ].length &&
                                    chat.image.startsWith("https")
                                    ? chat?.image
                                    : chat?.image &&
                                      chat?.image.split("?")[0].split("/")[
                                        chat?.image.split("?")[0].split("/")
                                          .length - 1
                                      ].length
                                      ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                      chat?.image
                                      : "";
                                let lastMsg = chat?.activity_status
                                  ? activityMessageDisplay(chat)
                                  : replaceIdWithNameUsingUnformattedList(
                                    chat.lastMessage,
                                    chat.taggedUserId,
                                    true
                                  );
                                const isMuted = muteNotificationList?.muteNotification?.some((muteData) => {
                                  if (chat.id === muteData.chatId) {
                                    const { dateTime, noLimit } = muteData;
                                    const currentTime = new Date();

                                    if (dateTime) {
                                      const muteTime = new Date(dateTime);
                                      return currentTime <= muteTime; // If the current time is before or equal to the muteTime, the chat is muted
                                    }

                                    if (noLimit) {
                                      return true; // If noLimit is true and dateTime is null, the chat is always muted
                                    }

                                    return false; // If dateTime is null and noLimit is false, the chat is not muted
                                  }

                                  return false; // If userid doesn't match muteData.chatId, return false
                                });
                                return (
                                  <ChatCell
                                    selectedClassName={userid === chat.id}
                                    staredLi={false}
                                    handleContextMenu={handleContextMenu}
                                    contextmenu={contextmenu}
                                    contextMenuId={contextMenuId}
                                    setContextMenuId={setcontextMenuId}
                                    quote_btn_start={quote_btn_start}
                                    setquote_btn_start={setquote_btn_start}
                                    quote_btn={quote_btn}
                                    setquotebtn={setquotebtn}
                                    setsidebar={setsidebar}
                                    setDisplaySearch={setDisplaySearch}
                                    settext={settext}
                                    setContextmenu={setcontextmenu}
                                    setContextMenuChatType={
                                      setcontextMenuChatType
                                    }
                                    setContextMenuName={setContextMenuName}
                                    handleStarUser={handleStarUser}
                                    handleClearChat={handleClear}
                                    setdeleteConversationPopup={
                                      setdeleteConversationPopup
                                    }
                                    key={chat.id}
                                    chat={chat}
                                    hasCounter={
                                      chat.id !== userid &&
                                      muteNotificationList &&
                                      !muteNotificationList?.notificationBadgeNotDisplay?.includes(
                                        chat.id
                                      ) &&
                                      chat?.count
                                    }
                                    hasSender={
                                      lastMsg
                                        ? chat.type === "chatChannel" ||
                                          chat.type === "userChatGroup"
                                          ? chat.activity_status
                                            ? false
                                            : chat?.sender?.id
                                              ? true
                                              : false
                                          : false
                                        : ""
                                    }
                                    hasText={
                                      chat?.activity_status ||
                                        chat.lastMessage.length > 0 ||
                                        chat?.messageType !== "text"
                                        ? true
                                        : false
                                    }
                                    messageCount={chat?.unreadMsg}
                                    messageType={
                                      chat?.messageType
                                        ? chat?.messageType.includes("text")
                                          ? "text"
                                          : chat?.messageType.includes(",")
                                            ? chat?.messageType
                                              .split(",")
                                              .pop() === "document"
                                              ? "file"
                                              : chat?.messageType.split(",").pop()
                                            : chat?.messageType === "document"
                                              ? "file"
                                              : chat?.messageType
                                        : "text"
                                    }
                                    muteStatus={isMuted ? "Muted" : "NotMuted"}
                                    name={
                                      chat?.firstname
                                        ? chat?.firstname
                                        : chat?.attendeeDetail.name
                                    }
                                    senderName={
                                      chat?.sender
                                        ? chat?.sender.id === myId
                                          ? "You"
                                          : chat?.sender.firstname
                                        : ""
                                    }
                                    type={
                                      chat.type === "chatChannel" ||
                                        chat.type === "userChatGroup"
                                        ? "Group"
                                        : "Personal"
                                    }
                                    time={sendTime}
                                    hasMentions={chat?.isMention}
                                    src={profileImage}
                                    handleClick={() =>
                                      handleChatListClickFunc(chat, chat?.count)
                                    }
                                    badgeStatus={
                                      onlineStatus > 0 ? "online" : "offline"
                                    }
                                    hasStatus={
                                      chat.type === "chatChannel" ||
                                        chat.type === "userChatGroup"
                                        ? false
                                        : true
                                    }
                                    isOnline={onlineStatus > 0}
                                    groupLable={
                                      chat?.firstname
                                        ? chat?.firstname
                                        : chat?.attendeeDetail.name
                                    }
                                    chatLastMessage={lastMsg}
                                  />
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {starChatListChannel.map((chat, index) => {
                                let profileImage =
                                  chat?.image &&
                                    chat?.image.split("?")[0].split("/")[
                                      chat?.image.split("?")[0].split("/")
                                        .length - 1
                                    ].length &&
                                    chat.image.startsWith("https")
                                    ? chat?.image
                                    : chat?.image &&
                                      chat?.image.split("?")[0].split("/")[
                                        chat?.image.split("?")[0].split("/")
                                          .length - 1
                                      ].length
                                      ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                      chat?.image
                                      : "";
                                const listDate = chat.userTimeStamp
                                  ? moment(
                                    moment.utc(chat.userTimeStamp).toDate()
                                  ).format("MMMM D, YYYY")
                                  : chat.createdAt && chat.createdAt.length
                                    ? moment(chat.createdAt).format(
                                      "MMMM D, YYYY"
                                    )
                                    : "";
                                const listTime = chat.userTimeStamp
                                  ? moment(
                                    moment.utc(chat.userTimeStamp).toDate()
                                  ).format("hh:mm A")
                                  : chat.createdAt && chat.createdAt.length
                                    ? moment(chat.createdAt).format("hh:mm A")
                                    : "";
                                const sendTime =
                                  listDate &&
                                    listDate.length &&
                                    listDate === moment().format("MMMM D, YYYY")
                                    ? listTime
                                    : listDate &&
                                      listDate.length &&
                                      moment().diff(listDate, "days") === 1
                                      ? "Yesterday"
                                      : listDate &&
                                        moment().diff(listDate, "days") < 7
                                        ? moment(listDate).format("dddd")
                                        : listDate &&
                                          moment().diff(listDate, "months") === 1
                                          ? moment(listDate).format("D MMM")
                                          : listDate && listDate.length > 0
                                            ? moment(listDate).format("DD.MM.YY")
                                            : listTime;
                                let lastMsg = chat?.activity_status
                                  ? chat.type !== "chatChannel" &&
                                  activityMessageDisplay(chat)
                                  : replaceIdWithNameUsingUnformattedList(
                                    chat.lastMessage,
                                    chat.taggedUserId,
                                    true
                                  );
                                const isMuted = muteNotificationList?.muteNotification?.some((muteData) => {
                                  if (chat.id === muteData.chatId) {
                                    const { dateTime, noLimit } = muteData;
                                    const currentTime = new Date();

                                    if (dateTime) {
                                      const muteTime = new Date(dateTime);
                                      return currentTime <= muteTime; // If the current time is before or equal to the muteTime, the chat is muted
                                    }

                                    if (noLimit) {
                                      return true; // If noLimit is true and dateTime is null, the chat is always muted
                                    }

                                    return false; // If dateTime is null and noLimit is false, the chat is not muted
                                  }

                                  return false; // If userid doesn't match muteData.chatId, return false
                                });
                                return (
                                  <ChatCell
                                    selectedClassName={userid === chat.id}
                                    staredLi={false}
                                    handleContextMenu={handleContextMenu}
                                    contextmenu={contextmenu}
                                    contextMenuId={contextMenuId}
                                    setContextMenuId={setcontextMenuId}
                                    quote_btn_start={quote_btn_start}
                                    setquote_btn_start={setquote_btn_start}
                                    quote_btn={quote_btn}
                                    setquotebtn={setquotebtn}
                                    setsidebar={setsidebar}
                                    setDisplaySearch={setDisplaySearch}
                                    settext={settext}
                                    setContextmenu={setcontextmenu}
                                    setContextMenuChatType={
                                      setcontextMenuChatType
                                    }
                                    setContextMenuName={setContextMenuName}
                                    handleStarUser={handleStarUser}
                                    handleClearChat={handleClear}
                                    setdeleteConversationPopup={
                                      setdeleteConversationPopup
                                    }
                                    key={chat.id}
                                    chat={chat}
                                    hasCounter={
                                      chat.id !== userid &&
                                      muteNotificationList &&
                                      !muteNotificationList?.notificationBadgeNotDisplay?.includes(
                                        chat.id
                                      ) &&
                                      chat?.count
                                    }
                                    hasSender={
                                      chat.type === "chatChannel" ||
                                        chat.type === "userChatGroup"
                                        ? chat.activity_status
                                          ? false
                                          : chat?.sender?.id
                                            ? true
                                            : false
                                        : false
                                    }
                                    hasText={
                                      chat?.activity_status ||
                                        chat.lastMessage.length > 0 ||
                                        chat?.messageType !== "text"
                                        ? true
                                        : false
                                    }
                                    messageCount={chat?.unreadMsg}
                                    messageType={
                                      chat?.messageType
                                        ? chat?.messageType.includes("text")
                                          ? "text"
                                          : chat?.messageType.includes(",")
                                            ? chat?.messageType
                                              .split(",")
                                              .pop() === "document"
                                              ? "file"
                                              : chat?.messageType.split(",").pop()
                                            : chat?.messageType === "document"
                                              ? "file"
                                              : chat?.messageType
                                        : "text"
                                    }
                                    muteStatus={isMuted ? "Muted" : "NotMuted"}
                                    name={
                                      chat?.firstname
                                        ? chat?.firstname
                                        : chat?.attendeeDetail.name
                                    }
                                    senderName={
                                      chat?.sender
                                        ? chat?.sender.id === myId
                                          ? "You"
                                          : chat?.sender.firstname
                                        : ""
                                    }
                                    type={
                                      chat.type === "chatChannel" ||
                                        chat.type === "userChatGroup"
                                        ? "Group"
                                        : "Personal"
                                    }
                                    time={sendTime}
                                    hasMentions={chat?.isMention}
                                    src={profileImage}
                                    handleClick={() =>
                                      handleChatListClickFunc(chat, chat?.count)
                                    }
                                    badgeStatus="offline"
                                    hasStatus={false} // need to change
                                    groupLable={
                                      chat?.firstname
                                        ? chat?.firstname
                                        : chat?.attendeeDetail.name
                                    }
                                    chatLastMessage={
                                      chat.type === "chatChannel" &&
                                      !chat.activity_status &&
                                      lastMsg
                                    }
                                  />
                                );
                              })}
                            </>
                          )
                        ) : null}
                      </div>
                      <hr />
                    </>
                  ) : null}
                </>
              ) : null}
              <div className="chat-sidebar-body-container">
                {hasAccordions &&
                  ((isChannelSelected && starChatListChannel.length) ||
                    (isMessageSelected && starChatListMessages.length)) ? (
                  <ChatAccordion
                    isCollapsed={collapsed1}
                    name={isChannelSelected ? "MDS Channels" : "Messages"}
                    handleToggle={handleToggleStarred}
                  />
                ) : (
                  <></>
                )}
                {hasAccordions &&
                  ((isChannelSelected && unstarChatListChannel.length) ||
                    (isMessageSelected && unstarChatListMessages.length)) &&
                  collapsed1 ? (
                  isMessageSelected ? (
                    <>
                      {unstarChatListMessages?.map((chat, index) => {
                        let onlineStatus =
                          chat &&
                          chatAllUsers &&
                          chatAllUsers?.filter(
                            (user) =>
                              user?.id === chat?.receiverId?._id &&
                              user?.onlineStatus
                          ).length;
                        let profileImage =
                          chat?.image &&
                            chat?.image.split("?")[0].split("/")[
                              chat?.image.split("?")[0].split("/").length - 1
                            ].length &&
                            chat.image.startsWith("https")
                            ? chat?.image
                            : chat?.image &&
                              chat?.image.split("?")[0].split("/")[
                                chat?.image.split("?")[0].split("/").length - 1
                              ].length
                              ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                              chat?.image
                              : "";
                        const listDate = chat.userTimeStamp
                          ? moment(
                            moment.utc(chat.userTimeStamp).toDate()
                          ).format("MMMM D, YYYY")
                          : chat.createdAt && chat.createdAt.length
                            ? moment(chat.createdAt).format("MMMM D, YYYY")
                            : "";
                        const listTime = chat.userTimeStamp
                          ? moment(
                            moment.utc(chat.userTimeStamp).toDate()
                          ).format("hh:mm A")
                          : chat.createdAt && chat.createdAt.length
                            ? moment(chat.createdAt).format("hh:mm A")
                            : "";
                        const sendTime =
                          listDate &&
                            listDate.length &&
                            listDate === moment().format("MMMM D, YYYY")
                            ? listTime
                            : listDate &&
                              listDate.length &&
                              moment().diff(listDate, "days") === 1
                              ? "Yesterday"
                              : listDate && moment().diff(listDate, "days") < 7
                                ? moment(listDate).format("dddd")
                                : listDate &&
                                  moment().diff(listDate, "months") === 1
                                  ? moment(listDate).format("D MMM")
                                  : listDate && listDate.length > 0
                                    ? moment(listDate).format("DD.MM.YY") // Change the format
                                    : listTime;
                        let lastMsg = chat?.activity_status
                          ? activityMessageDisplay(chat)
                          : replaceIdWithNameUsingUnformattedList(
                            chat.lastMessage,
                            chat.taggedUserId,
                            true
                          );
                        const isMuted = muteNotificationList?.muteNotification?.some((muteData) => {
                          if (chat.id === muteData.chatId) {
                            const { dateTime, noLimit } = muteData;
                            const currentTime = new Date();

                            if (dateTime) {
                              const muteTime = new Date(dateTime);
                              return currentTime <= muteTime; // If the current time is before or equal to the muteTime, the chat is muted
                            }

                            if (noLimit) {
                              return true; // If noLimit is true and dateTime is null, the chat is always muted
                            }

                            return false; // If dateTime is null and noLimit is false, the chat is not muted
                          }

                          return false; // If userid doesn't match muteData.chatId, return false
                        });
                        return (
                          <ChatCell
                            selectedClassName={userid === chat.id}
                            isOnline={onlineStatus > 0}
                            staredLi={false}
                            handleContextMenu={handleContextMenu}
                            contextmenu={contextmenu}
                            contextMenuId={contextMenuId}
                            setContextMenuId={setcontextMenuId}
                            quote_btn_start={quote_btn_start}
                            setquote_btn_start={setquote_btn_start}
                            quote_btn={quote_btn}
                            setquotebtn={setquotebtn}
                            setsidebar={setsidebar}
                            setDisplaySearch={setDisplaySearch}
                            settext={settext}
                            setContextmenu={setcontextmenu}
                            setContextMenuChatType={setcontextMenuChatType}
                            setContextMenuName={setContextMenuName}
                            handleStarUser={handleStarUser}
                            handleClearChat={handleClear}
                            setdeleteConversationPopup={
                              setdeleteConversationPopup
                            }
                            key={chat.id}
                            chat={chat}
                            hasCounter={
                              chat.id !== userid &&
                              muteNotificationList &&
                              !muteNotificationList?.notificationBadgeNotDisplay?.includes(
                                chat.id
                              ) &&
                              chat?.count
                            }
                            hasSender={
                              chat.type === "chatChannel" ||
                                chat.type === "userChatGroup"
                                ? chat.activity_status
                                  ? false
                                  : chat?.sender?.id
                                    ? true
                                    : false
                                : false
                            }
                            hasText={
                              chat?.activity_status ||
                                chat.lastMessage.length > 0 ||
                                chat?.messageType !== "text"
                                ? true
                                : false
                            }
                            messageCount={chat?.unreadMsg}
                            messageType={
                              chat?.messageType
                                ? chat?.messageType.includes("text")
                                  ? "text"
                                  : chat?.messageType.includes(",")
                                    ? chat?.messageType.split(",").pop() ===
                                      "document"
                                      ? "file"
                                      : chat?.messageType.split(",").pop()
                                    : chat?.messageType === "document"
                                      ? "file"
                                      : chat?.messageType
                                : "text"
                            }
                            muteStatus={isMuted ? "Muted" : "NotMuted"}
                            name={
                              chat?.firstname
                                ? chat?.firstname
                                : chat?.attendeeDetail.name
                            }
                            senderName={
                              chat?.sender
                                ? chat?.sender.id === myId
                                  ? "You"
                                  : chat?.sender.firstname
                                : ""
                            }
                            type={
                              chat.type === "chatChannel" ||
                                chat.type === "userChatGroup"
                                ? "Group"
                                : "Personal"
                            }
                            time={sendTime}
                            hasMentions={chat?.isMention}
                            src={profileImage}
                            handleClick={() =>
                              handleChatListClickFunc(chat, chat?.count)
                            }
                            badgeStatus={
                              onlineStatus > 0 ? "online" : "offline"
                            }
                            hasStatus={
                              chat.type === "chatChannel" ||
                                chat.type === "userChatGroup"
                                ? false
                                : true
                            } // need to change
                            groupLable={
                              chat?.firstname
                                ? chat?.firstname
                                : chat?.attendeeDetail.name
                            }
                            chatLastMessage={lastMsg}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {unstarChatListChannel.map((chat, index) => {
                        let profileImage =
                          chat?.image &&
                            chat?.image.split("?")[0].split("/")[
                              chat?.image.split("?")[0].split("/").length - 1
                            ].length &&
                            chat.image.startsWith("https")
                            ? chat?.image
                            : chat?.image &&
                              chat?.image.split("?")[0].split("/")[
                                chat?.image.split("?")[0].split("/").length - 1
                              ].length
                              ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                              chat?.image
                              : "";
                        const listDate = chat.userTimeStamp
                          ? moment(
                            moment.utc(chat.userTimeStamp).toDate()
                          ).format("MMMM D, YYYY")
                          : chat.createdAt && chat.createdAt.length
                            ? moment(chat.createdAt).format("MMMM D, YYYY")
                            : "";
                        const listTime = chat.userTimeStamp
                          ? moment(
                            moment.utc(chat.userTimeStamp).toDate()
                          ).format("hh:mm A")
                          : chat.createdAt && chat.createdAt.length
                            ? moment(chat.createdAt).format("hh:mm A")
                            : "";
                        const sendTime =
                          listDate &&
                            listDate.length &&
                            listDate === moment().format("MMMM D, YYYY")
                            ? listTime
                            : listDate &&
                              listDate.length &&
                              moment().diff(listDate, "days") === 1
                              ? "Yesterday"
                              : listDate && moment().diff(listDate, "days") < 7
                                ? moment(listDate).format("dddd")
                                : listDate &&
                                  moment().diff(listDate, "months") === 1
                                  ? moment(listDate).format("D MMM")
                                  : listDate && listDate.length > 0
                                    ? moment(listDate).format("DD.MM.YY") // Change the format
                                    : listTime;
                        let lastMsg = chat?.activity_status
                          ? chat.type !== "chatChannel" &&
                          activityMessageDisplay(chat)
                          : replaceIdWithNameUsingUnformattedList(
                            chat.lastMessage,
                            chat.taggedUserId,
                            true
                          );
                        const isMuted = muteNotificationList?.muteNotification?.some((muteData) => {
                          if (chat.id === muteData.chatId) {
                            const { dateTime, noLimit } = muteData;
                            const currentTime = new Date();

                            if (dateTime) {
                              const muteTime = new Date(dateTime);
                              return currentTime <= muteTime; // If the current time is before or equal to the muteTime, the chat is muted
                            }

                            if (noLimit) {
                              return true; // If noLimit is true and dateTime is null, the chat is always muted
                            }

                            return false; // If dateTime is null and noLimit is false, the chat is not muted
                          }

                          return false; // If userid doesn't match muteData.chatId, return false
                        });
                        return (
                          <ChatCell
                            selectedClassName={userid === chat.id}
                            staredLi={false}
                            handleContextMenu={handleContextMenu}
                            contextmenu={contextmenu}
                            contextMenuId={contextMenuId}
                            setContextMenuId={setcontextMenuId}
                            quote_btn_start={quote_btn_start}
                            setquote_btn_start={setquote_btn_start}
                            quote_btn={quote_btn}
                            setquotebtn={setquotebtn}
                            setsidebar={setsidebar}
                            setDisplaySearch={setDisplaySearch}
                            settext={settext}
                            setContextmenu={setcontextmenu}
                            setContextMenuChatType={setcontextMenuChatType}
                            setContextMenuName={setContextMenuName}
                            handleStarUser={handleStarUser}
                            handleClearChat={handleClear}
                            setdeleteConversationPopup={
                              setdeleteConversationPopup
                            }
                            key={chat.id}
                            chat={chat}
                            hasCounter={
                              chat.id !== userid &&
                              muteNotificationList &&
                              !muteNotificationList?.notificationBadgeNotDisplay?.includes(
                                chat.id
                              ) &&
                              chat?.count
                            }
                            hasSender={
                              chat.type === "chatChannel" ||
                                chat.type === "userChatGroup"
                                ? chat.activity_status
                                  ? false
                                  : chat?.sender?.id
                                    ? true
                                    : false
                                : false
                            }
                            hasText={
                              chat?.activity_status ||
                                chat.lastMessage.length > 0 ||
                                chat?.messageType !== "text"
                                ? true
                                : false
                            }
                            messageCount={chat?.unreadMsg}
                            messageType={
                              chat?.messageType
                                ? chat?.messageType.includes("text")
                                  ? "text"
                                  : chat?.messageType.includes(",")
                                    ? chat?.messageType.split(",").pop() ===
                                      "document"
                                      ? "file"
                                      : chat?.messageType.split(",").pop()
                                    : chat?.messageType === "document"
                                      ? "file"
                                      : chat?.messageType
                                : "text"
                            }
                            muteStatus={isMuted ? "Muted" : "NotMuted"}
                            name={
                              chat?.firstname
                                ? chat?.firstname
                                : chat?.attendeeDetail.name
                            }
                            senderName={
                              chat?.sender
                                ? chat?.sender.id === myId
                                  ? "You"
                                  : chat?.sender.firstname
                                : ""
                            }
                            type={
                              chat.type === "chatChannel" ||
                                chat.type === "userChatGroup"
                                ? "Group"
                                : "Personal"
                            }
                            time={sendTime}
                            hasMentions={chat?.isMention}
                            src={profileImage}
                            handleClick={() =>
                              handleChatListClickFunc(chat, chat?.count)
                            }
                            badgeStatus={false}
                            hasStatus={false} // need to change
                            groupLable={
                              chat?.firstname
                                ? chat?.firstname
                                : chat?.attendeeDetail.name
                            }
                            chatLastMessage={
                              chat.type === "chatChannel" &&
                              !chat.activity_status &&
                              lastMsg
                            }
                          />
                        );
                      })}
                    </>
                  )
                ) : (
                  <></>
                )}
              </div>
              <div className="chat-sidebar-chat-btn">
                {isMessageSelected && hasFloatingButton && (
                  <ChatFloatingButtonPrimary
                    openChatListModel={openChatListModel}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

ChatSideBar.propTypes = {
  type: PropTypes.oneOf(["Channels", "Messages", "NoChannels", "NoMessages"]),
  hasAccordions: PropTypes.bool,
  hasFloatingButton: PropTypes.bool,
  isActive: PropTypes.bool,
  handleClick: PropTypes.func,
  chatType: PropTypes.oneOf(["Personal", "Group"]),
  groupLable: PropTypes.string,
  src: PropTypes.string,
  name: PropTypes.string,
  muteStatus: PropTypes.oneOf(["NotMuted", "SoundOff", "Muted"]),
  isOnline: PropTypes.bool,
  hasStatus: PropTypes.bool,
  hasText: PropTypes.bool,
  chatCellHasCounter: PropTypes.bool,
  hasMentions: PropTypes.bool,
  time: PropTypes.string,
  messageType: PropTypes.oneOf(["text", "image", "video", "file"]),
  senderName: PropTypes.string,
  messageCount: PropTypes.string,
  hasSender: PropTypes.bool,
  handleChatListClick: PropTypes.func,
  accordionLable: PropTypes.string,
  accordionLable1: PropTypes.string,
  isCollapsed: PropTypes.bool,
  isCollapsed1: PropTypes.bool,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  id: PropTypes.string,
  hasBadge: PropTypes.bool,
  hasCounter: PropTypes.bool,
  counter: PropTypes.string,
  label1: PropTypes.string,
  isSelected1: PropTypes.bool,
  hasBadge1: PropTypes.bool,
  hasCounter1: PropTypes.bool,
  counter1: PropTypes.string,
  settext: PropTypes.string,
  setdeleteConversationPopup: PropTypes.string,
  handleStarUser: PropTypes.string,
  setDisplaySearch: PropTypes.string,
  contextMenuId: PropTypes.number,
  setcontextMenuId: PropTypes.string,
  quote_btn_start: PropTypes.bool,
  setquote_btn_start: PropTypes.string,
  quote_btn: PropTypes.bool,
  setquotebtn: PropTypes.string,
  contextMenuChatType: PropTypes.string,
  setcontextMenuChatType: PropTypes.string,
  contextmenu: PropTypes.string,
  setcontextmenu: PropTypes.string,
  setsidebar: PropTypes.string,
  muteNotificationList: PropTypes.array,
  handleContextMenu: PropTypes.func,
  handleChatListClickFunc: PropTypes.func,
  activityMessageDisplay: PropTypes.func,
  handleSearchBar: PropTypes.func,
  handleClear: PropTypes.func,
  searchInput: PropTypes.string,
  chatList: PropTypes.array,
  starchatuser: PropTypes.array,
  starChatListChannel: PropTypes.array,
  starChatListMessages: PropTypes.array,
  unstarChatListChannel: PropTypes.array,
  unstarChatListMessages: PropTypes.array,
};

export default ChatSideBar;
