import React, { useEffect, useState } from 'react'
import { searchAndSortArray } from './CommonFunction';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import authServiceEvent from "../../../Services/auth.service.event";

const SearchAndSorting = ({ children, currentArray, createObjectFromArray, sortingOn = "", searchOn = [], placeholderText, displayFilter, setFilterType, filterType, handleTypeFilter, searchInputId = "", sortingPatternId = "", getAllNewsPost, suggestionList, setSuggestions, setSuggestionBox, listIndex, setListIndex, suggestions, suggestionBox, tabInformation, recallApi, clearSearch, setClearSearch, searchedText, setSearchedText, againLoad, setAgainLoad }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");

  const [searchText, setSearchText] = useState("");
  const [actualsearch, setActualsearch] = useState("");
  const [sortingPattern, setSortingPattern] = useState("");
  const history = useLocation().search
  const id = new URLSearchParams(history).get("id")
  const search = new URLSearchParams(history).get("search");
  const [validRoleType, setValidRoleType] = useState([]);


  const navigate = useNavigate()

  useEffect(() => {

    setSearchText(search !== undefined && search !== null ? search : searchText ? searchText : "")
  }, [])

  useEffect(() => {
    getAllParticipantTypes()
  }, [tabInformation])

  //to load filter types dropdown when remove or add filter type 
  useEffect(() => {
    if (againLoad) {
      getAllParticipantTypes()
      setAgainLoad(false)
    }


  }, [againLoad])


  const getAllParticipantTypes = () => {
    authServiceEvent
      .searchEventParticipantTypesApi(queryVariable, "")
      .then((res) => {
        const rolesTypesData = res.data.list;
        setValidRoleType(rolesTypesData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (clearSearch) {
      setSearchText("")
      setSearchedText !== undefined && setSearchedText("")
      setFilterType !== undefined && setFilterType("all")
      setClearSearch !== undefined && setClearSearch(false)
    }
  }, [clearSearch])

  useEffect(() => {
    setSearchedText !== undefined && setSearchedText(searchText)
  }, [searchText])

  useEffect(() => {

    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex]
      if (currentList) {
        var text = currentList.innerText
        setSearchText(text);

      }
    }

  }, [listIndex]);

  const handleSearch = (text) => {
    if (recallApi !== undefined) {
      if (filterType !== undefined && filterType !== null && filterType !== "")
        recallApi(text, "","",filterType);
      else
        recallApi(text);
      location.pathname === "/events/detail" && navigate("/events/detail?id=" + id + `&&search=${text}`)
    } else {
      const filteredArray = searchAndSortArray(currentArray, text.trim(), searchOn, sortingPattern, sortingOn, filterType, displayFilter)
      createObjectFromArray(filteredArray, false)
      setSearchText(text)

    }
    setSuggestionBox !== undefined && setSuggestionBox(false)

  }
  return (
    <div className="uic-fl mt-0 d-flex flex-wrap gap-xl-4 gap-3 align-items-center">
      <div className="search-input position-relative mb-0 me-0 flex-grow-1">
        <input
          id={searchInputId}
          type="search"
          placeholder={placeholderText}
          className="w-100"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            var currentValue;
            if (e.target.value) {
              currentValue = e.target.value;
            } else {
              currentValue = e.target.value;
              setSearchText(currentValue);

            }
            setActualsearch(currentValue);
            if (suggestionList !== undefined && suggestionList.length > 0) {
              setListIndex(-1);
              setSuggestionBox(true)
              var searchResult = suggestionList.filter((user) => {
                return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
              });
              setSuggestions(searchResult);
            }
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              handleSearch(e.target.value)
            } else if (e.key === "ArrowDown") {
              const listLength =
                suggestions.length > 15
                  ? 15 : suggestions.length;
              if (listIndex < listLength - 1) {
                setListIndex(listIndex + 1);
              } else if (listIndex === listLength - 1) {
                setListIndex(-1);
                setSearchText(actualsearch);

              }
            } else if (e.key === "ArrowUp") {
              e.preventDefault();
              const listLength =
                suggestions.length > 15
                  ? 15
                  : suggestions.length;
              if (listIndex === 0) {
                setListIndex(-1);
                setSearchText(actualsearch);

              } else if (listIndex === -1) {
                setListIndex(listLength - 1);
              } else {
                setListIndex(listIndex - 1);
              }
            }
          }}
          onBlur={() => setSuggestionBox !== undefined && setSuggestionBox(false)}
        />
        {suggestionBox && suggestions.length > 0 && searchText.trim() && (
          <div className="suggestion-box">
            <ul id='suggestion-list'>
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onMouseDown={() => handleSearch(suggestion.name)}
                  className={index === listIndex ? 'selected-active' : ''}
                >
                  {searchText !== undefined && searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length)} </> : suggestion.name}
                </li>
              ))}
            </ul>
          </div>
        )}
        <i className="fa fa-search" onClick={() => { handleSearch(searchText) }}></i>
      </div>
      {displayFilter &&
        <div className="filter-by-wrapper d-flex align-items-center flex-grow-1">
          <div className="sort-by-mn-title flex-shrink-0 w-fit">Filter by : </div>
          <div className="sort-by-select-dropdown w-100">
            <select
              value={filterType.toString()}
              onChange={(e) => {
                // setSearchText("")
                setFilterType(e.target.value);
                handleTypeFilter(e.target.value);
              }}
            >
              <option value="all">All</option>
              {validRoleType.map((t) => {
                if (t.total_attendees > 0)
                  return <option value={t._id.toString()}>{t.role}</option>;
              })}


              {/* <option value="Member">Member</option>
              <option value="Partner">Partner</option>
              <option value="Guest">Guest</option>
              <option value="Speaker">Speaker</option> */}
            </select>
          </div>
        </div>}
        {children}
    </div>
  )
}

export default SearchAndSorting
