import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import exportImg from "../../Images/exportimg.svg";
import ExportSelect from "../Admin/PartnerManagement/PartnerModals/ExportSelect";

const NotifiedUsertable = ({
  users = [],
  setModal,
  FieldChange = false,
  FromChannelList = false,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const columns = [
    { id: "name", label: "User Name", width: 50 },
    { id: "email", label: "Email", width: 50 },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  const [selectError, setSelectError] = useState("");
  const [error, setError] = useState(false);

  //pagination for page change
  const handleChangeUserPage = (event, newPage) => {
    setPage(newPage);
  };

  //pagination for rows per page change
  const handleChangeUserRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Export for download xlsx file
  const handleExport = () => {
    const workbook_ = XLSX.utils.book_new(); // create new workbook
    if (selectRow.length > 0) {
      const modifiedClaimData = selectRow.map((item) => {
        let userName = "";
        userName = item.display_name;
        const email = item["Preferred Email"];

        return {
          "User Name": userName,
          Email: email,
        };
      });
      const convertToexcel = XLSX.utils.json_to_sheet(modifiedClaimData); // convert modified JSON data to sheet

      XLSX.utils.book_append_sheet(workbook_, convertToexcel, "Sheet2"); // add the sheet to the workbook
      const downloadExcel = XLSX.write(workbook_, {
        bookType: "xlsx",
        type: "array",
      });
      const dataExcel = new Blob([downloadExcel], { type: fileType });

      FileSaver.saveAs(dataExcel, "Notifications_To_Send.xlsx");
    } else {
      setError(true);
      setSelectError("Please select checkbox from column");
    }
  };

  return (
    <>
      <div className="desk-globle-model-box globle-model-box notification-user-list-popup">
        <div className="desk-globle-model-box-inner">
          <div className="modal-content">
            <div class="cstm-replace-pp">
              <div className="cstm-hdr">
                <span
                  class="cstm-rpp-close-btn"
                  onClick={() => setModal(false)}
                >
                  ×
                </span>
                <div class="cstm-rpp-title">
                  {FromChannelList
                    ? `Preview ${users?.length} people who are in this channel right now`
                    : "Notify the applicable user profiles"}
                </div>
              </div>
              <div className="cstm-export-link">
                <a onClick={handleExport}>
                  {" "}
                  <img src={exportImg} />
                  Export
                </a>
              </div>
              <div className="cstm-cstm-viewlist">
                {error && <div className="alert alert-info">{selectError}</div>}
                <Paper className="uic-tb-mn ">
                  <TableContainer className=" uic-table-main">
                    <Table sx={{ "& td, th": { padding: "12px" } }}>
                      <TableHead className="uic-table-th">
                        <TableRow>
                          <TableCell className="uic-th-td">
                            <Checkbox
                              className="cstm-ls-checkbox p-0"
                              color="primary"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const newSelecteds = users.map(
                                    (row) => row._id
                                  );
                                  const newSelecteds1 = users.map((row) => row);
                                  setSelected(newSelecteds);
                                  setSelectRow(newSelecteds1);
                                  setSelectError("");
                                  setError(false);
                                } else {
                                  setSelected([]);
                                  setSelectRow([]);
                                  setError(true);
                                  setSelectError(
                                    "Please select checkbox from column"
                                  );
                                }
                              }}
                              inputProps={{
                                "aria-label": "select all desserts",
                              }}
                            />
                          </TableCell>
                          {columns.map((colmn) => (
                            <TableCell
                              key={colmn.id}
                              className={`uic-th-td w-${colmn.width}`}
                            >
                              {colmn.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users.length > 0 ? (
                          users
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, i) => {
                              // take first name from attendee detail
                              const display_name = row.display_name;
                              // take last name from attendee detail
                              //const lastName = row.last_name;
                              let name = display_name;
                              return (
                                <TableRow>
                                  <TableCell className="uic-th-td">
                                    <ExportSelect
                                      id={row._id}
                                      row={row}
                                      selected={selected}
                                      setSelected={setSelected}
                                      selectRow={selectRow}
                                      setSelectRow={setSelectRow}
                                      setSelectError={setSelectError}
                                    />
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {name}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {FieldChange
                                      ? row.email
                                      : row["Preferred Email"]}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <span className="no-data-found">No data found</span>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="uic-pagination"
                    rowsPerPageOptions={[]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangeUserPage}
                    onRowsPerPageChange={handleChangeUserRowsPerPage}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotifiedUsertable;
