import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TickIcon from "../../Icons/GeneralIcons/TickIcon";
import theme from "../../../Styles/Theme/theme";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const CheckInCollapesRow = ({
  rowData,
  checkInLoader,
  handleCheckInButtonClick,
  loaderRowId,
  loaderUserId,
  eventId,
  handleUserDetail,
  setCheckInModal,
  eventName,
  collapesRowState,
  setCollapesRowState,
}) => {
  const navigate = useNavigate();

  //Count the checkin's per row
  const checkInRowCount =
    (rowData?.eventCheckInData?.status === "checkIn" ? 1 : 0) +
    (rowData?.event_activity?.length > 0 &&
      (rowData?.event_activity?.filter(
        (activity) => activity?.status === "checkIn"
      )).length);

  const handleClick = (id) => {
    setCollapesRowState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  //Attendee type
  const attendeeType =
    Object.keys(rowData).length > 0 &&
    rowData?.roleDetail?.map((detail) => detail.role).join(", ");

  return (
    <>
      <TableRow
        sx={{
          "&:hover": {
            backgroundColor: "#dddddd33",
          },
        }}
      >
        <TableCell padding="normal" sx={{ width: "1%", paddingRight: "0" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ flexShrink: "0" }}
            onClick={() => handleClick(rowData?._id)}
          >
            {collapesRowState[rowData?._id] ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(
              `/events/detail?id=${eventId}&tab=checkin&checkin-id=${rowData?._id}`
            );
            handleUserDetail(rowData?._id);
            setCheckInModal(true);
            document.body.classList.add("modal-open");
          }}
        >
          <Box>
            <Stack direction="row" spacing={"16px"} alignItems={"center"}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  width: "100%",
                }}
              >
                {rowData.profileImg !== undefined &&
                rowData.profileImg !== null &&
                rowData.profileImg !== "" ? (
                  <Avatar
                    sx={{ width: 32, height: 32, flexShrink: "0" }}
                    alt="Avatar"
                    src={rowData.profileImg}
                    variant="circular"
                  />
                ) : (
                  <Avatar sx={{ width: 32, height: 32, flexShrink: "0" }}>
                    {rowData["first_name"]
                      ? rowData["first_name"].charAt(0)
                      : ""}
                  </Avatar>
                )}
                <Box>
                  <Typography
                    variant="body2"
                    color={theme.palette.text.primary}
                  >
                    {/* {rowData.display_name} */}
                    {`${rowData?.first_name} ${rowData?.last_name}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color={theme.palette.text.secondary}
                  >
                    {rowData["Preferred Email"]}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </TableCell>
        <TableCell
          align="center"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(
              `/events/detail?id=${eventId}&tab=checkin&checkin-id=${rowData?._id}`
            );
            handleUserDetail(rowData?._id);
            setCheckInModal(true);
            document.body.classList.add("modal-open");
          }}
        >
          <Typography variant="body1" color={theme.palette.text.primary}>
            {rowData?.purchased_ticket?.length > 0
              ? rowData?.purchased_ticket[0]?.name
              : "-"}
          </Typography>
        </TableCell>
        <TableCell
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(
              `/events/detail?id=${eventId}&tab=checkin&checkin-id=${rowData?._id}`
            );
            handleUserDetail(rowData?._id);
            setCheckInModal(true);
            document.body.classList.add("modal-open");
          }}
        >
          <Typography variant="body1" color={theme.palette.text.primary}>
            {attendeeType}
          </Typography>
        </TableCell>
        {eventName === "MDS Summit Denver" && (
          <TableCell
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(
                `/events/detail?id=${eventId}&tab=checkin&checkin-id=${rowData?._id}`
              );
              handleUserDetail(rowData?._id);
              setCheckInModal(true);
              document.body.classList.add("modal-open");
            }}
          >
            <Typography variant="body1" color={theme.palette.text.primary}>
              {rowData["Denver Check in Form"] !== undefined &&
              rowData["Denver Check in Form"] !== null &&
              rowData["Denver Check in Form"] !== "false"
                ? "Yes"
                : "No"}
            </Typography>
          </TableCell>
        )}
        <TableCell align="right">
          <Typography
            variant="body1"
            color={theme.palette.text.primary}
          >{`${checkInRowCount} of ${
            rowData?.event_activity?.length + 1
          }`}</Typography>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          "&:hover": {
            backgroundColor: "#dddddd33",
          },
        }}
      >
        <TableCell
          padding="none"
          colSpan={6}
          sx={{ borderBottom: !collapesRowState[rowData?._id] && "unset" }}
        >
          <Collapse
            in={collapesRowState[rowData?._id]}
            timeout="auto"
            unmountOnExit
          >
            <Table size="medium" aria-label="purchases">
              <TableBody
                sx={{ "& tr:last-child > *": { borderBottom: "unset" } }}
              >
                {Object.keys(rowData.eventCheckInData).length > 0 && (
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body1"
                        color={theme.palette.text.primary}
                        pl={2}
                      >
                        {rowData?.eventCheckInData?.title}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        color={theme.palette.text.primary}
                      ></Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        color={theme.palette.text.primary}
                      ></Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      padding="none"
                      sx={{ padding: "0 10px" }}
                    >
                      {checkInLoader &&
                      rowData?._id === loaderUserId &&
                      !loaderRowId ? (
                        <LoadingButton
                          sx={{
                            "& svg .MuiCircularProgress-circle": {
                              stroke: "#000",
                            },
                          }}
                          size="small"
                          loading={true}
                          variant="text"
                        ></LoadingButton>
                      ) : (
                        <Button
                          sx={{ width: "max-content" }}
                          size="small"
                          variant="text"
                          color={
                            rowData?.eventCheckInData?.status === "checkIn"
                              ? "success"
                              : "primary"
                          }
                          startIcon={
                            rowData?.eventCheckInData?.status === "checkIn" && (
                              <TickIcon
                                height={20}
                                width={20}
                                iconColor="currentColor"
                              />
                            )
                          }
                          onClick={() =>
                            handleCheckInButtonClick(
                              rowData?._id,
                              rowData?.eventCheckInData?.status,
                              "event"
                            )
                          }
                        >
                          {rowData?.eventCheckInData?.status === "checkIn"
                            ? "Checked"
                            : "Check in"}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                )}
                {rowData?.event_activity?.length > 0 &&
                  rowData?.event_activity?.map((activityItem) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="body1"
                            color={theme.palette.text.primary}
                            pl={2}
                          >
                            {activityItem?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            color={theme.palette.text.primary}
                          ></Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            color={theme.palette.text.primary}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          padding="none"
                          sx={{ padding: "0 10px" }}
                        >
                          {checkInLoader &&
                          activityItem._id === loaderRowId &&
                          rowData?._id === loaderUserId ? (
                            <LoadingButton
                              sx={{
                                "& svg .MuiCircularProgress-circle": {
                                  stroke: "#000",
                                },
                              }}
                              size="small"
                              loading={true}
                              variant="text"
                            ></LoadingButton>
                          ) : (
                            <Button
                              sx={{ width: "max-content" }}
                              size="small"
                              variant="text"
                              color={
                                activityItem?.status === "checkIn"
                                  ? "success"
                                  : "primary"
                              }
                              startIcon={
                                activityItem?.status === "checkIn" && (
                                  <TickIcon
                                    height={20}
                                    width={20}
                                    iconColor="currentColor"
                                  />
                                )
                              }
                              onClick={() =>
                                handleCheckInButtonClick(
                                  rowData?._id,
                                  activityItem?.status,
                                  "activity",
                                  activityItem?._id
                                )
                              }
                            >
                              {activityItem?.status === "checkIn"
                                ? "Checked"
                                : "Check in"}
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CheckInCollapesRow;
