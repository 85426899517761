import {
  Avatar,
  Box,
  Button,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import TickIcon from "../../Icons/GeneralIcons/TickIcon";
import theme from "../../../Styles/Theme/theme";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

const ActivityCheckInTable = ({
  rowData,
  checkInLoader,
  handleCheckInButtonClick,
  loaderRowId,
  loaderUserId,
  eventId,
  handleUserDetail,
  setCheckInModal,
  selectedEventId,
  eventName,
}) => {
  const navigate = useNavigate();
  //Attendee type
  const attendeeType =
    Object.keys(rowData).length > 0 &&
    rowData?.roleDetail?.map((detail) => detail.role).join(", ");

  //Checkin desk filter button click
  const handleCheckInButtonFromFilter = () => {
    if (selectedEventId === eventId) {
      handleCheckInButtonClick(
        rowData?._id,
        rowData?.eventCheckInData?.status,
        "event"
      );
    } else {
      handleCheckInButtonClick(
        rowData?._id,
        rowData?.event_activity[0]?.status,
        "activity",
        rowData?.event_activity[0]?._id
      );
    }
  };
  return (
    <>
      <TableRow
        sx={{
          "&:hover": {
            backgroundColor: "#dddddd33",
          },
        }}
      >
        <TableCell
          sx={{ cursor: "pointer" }}
          colSpan={"2"}
          onClick={() => {
            navigate(
              `/events/detail?id=${eventId}&tab=checkin&checkin-id=${rowData?._id}`
            );
            handleUserDetail(rowData?._id);
            setCheckInModal(true);
            document.body.classList.add("modal-open");
          }}
        >
          <Box>
            <Stack direction="row" spacing={"16px"} alignItems={"center"}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  width: "100%",
                }}
              >
                {rowData.profileImg !== undefined &&
                rowData.profileImg !== null &&
                rowData.profileImg !== "" ? (
                  <Avatar
                    sx={{ width: 32, height: 32, flexShrink: "0" }}
                    alt="Avatar"
                    src={rowData.profileImg}
                    variant="circular"
                  />
                ) : (
                  <Avatar sx={{ width: 32, height: 32, flexShrink: "0" }}>
                    {rowData["first_name"]
                      ? rowData["first_name"].charAt(0)
                      : ""}
                  </Avatar>
                )}
                <Box>
                  <Typography
                    variant="body2"
                    color={theme.palette.text.primary}
                  >
                    {rowData.display_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color={theme.palette.text.secondary}
                  >
                    {rowData["Preferred Email"]}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        </TableCell>
        <TableCell
          align="center"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(
              `/events/detail?id=${eventId}&tab=checkin&checkin-id=${rowData?._id}`
            );
            handleUserDetail(rowData?._id);
            setCheckInModal(true);
            document.body.classList.add("modal-open");
          }}
        >
          <Typography variant="body1" color={theme.palette.text.primary}>
            {rowData?.purchased_ticket?.length > 0
              ? rowData?.purchased_ticket[0]?.name
              : "-"}
          </Typography>
        </TableCell>
        <TableCell
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(
              `/events/detail?id=${eventId}&tab=checkin&checkin-id=${rowData?._id}`
            );
            handleUserDetail(rowData?._id);
            setCheckInModal(true);
            document.body.classList.add("modal-open");
          }}
        >
          <Typography variant="body1" color={theme.palette.text.primary}>
            {attendeeType}
          </Typography>
        </TableCell>
        {eventName === "MDS Summit Denver" && (
          <TableCell
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate(
                `/events/detail?id=${eventId}&tab=checkin&checkin-id=${rowData?._id}`
              );
              handleUserDetail(rowData?._id);
              setCheckInModal(true);
              document.body.classList.add("modal-open");
            }}
          >
            <Typography variant="body1" color={theme.palette.text.primary}>
              {rowData["Denver Check in Form"] !== undefined &&
              rowData["Denver Check in Form"] !== null &&
              rowData["Denver Check in Form"] !== "false"
                ? "Yes"
                : "No"}
            </Typography>
          </TableCell>
        )}
        <TableCell align="right">
          {checkInLoader &&
          rowData?.event_activity[0]?._id === loaderRowId &&
          rowData?._id === loaderUserId ? (
            <LoadingButton
              sx={{
                "& svg .MuiCircularProgress-circle": {
                  stroke: "#000",
                },
              }}
              size="small"
              loading={true}
              variant="text"
            ></LoadingButton>
          ) : checkInLoader && rowData?._id === loaderUserId && !loaderRowId ? (
            <LoadingButton
              sx={{
                "& svg .MuiCircularProgress-circle": {
                  stroke: "#000",
                },
              }}
              size="small"
              loading={true}
              variant="text"
            ></LoadingButton>
          ) : (
            <Button
              sx={{ width: "max-content" }}
              size="small"
              variant="text"
              color={
                selectedEventId !== ""
                  ? rowData?.eventCheckInData?.status === "checkIn"
                    ? "success"
                    : "primary"
                  : rowData?.event_activity[0]?.status === "checkIn"
                  ? "success"
                  : "primary"
              }
              startIcon={
                (selectedEventId !== ""
                  ? rowData?.eventCheckInData?.status === "checkIn"
                  : rowData?.event_activity[0]?.status === "checkIn") && (
                  <TickIcon height={20} width={20} iconColor="currentColor" />
                )
              }
              onClick={handleCheckInButtonFromFilter}
            >
              {selectedEventId !== ""
                ? rowData?.eventCheckInData?.status === "checkIn"
                  ? "Checked"
                  : "Check in"
                : rowData?.event_activity[0]?.status === "checkIn"
                ? "Checked"
                : "Check in"}
            </Button>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default ActivityCheckInTable;
