import React from "react";
import PropTypes from "prop-types";
// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/ChatData.css";

const ChatData = ({
  variant = "Date",
  date = "19 Aug",
  name = "Name",
  groupName = "",
  memberCount = "Member Name",
  channelName = "Channel Name",
  userName = "User's name",
  oldName = "old name",
  newName = "new name",
}) => {
  return (
    <>
      {variant === "Date" && (
        <div className="chatdata-sec flex justify-center">{date}</div>
      )}
      {variant === "GroupCreated" && (
        <div className="chatdata-sec flex justify-center">
          <span> {name} </span> created the <span> {groupName} </span> group
        </div>
      )}
      {variant === "GroupLimit" && (
        <div className="chatdata-sec flex justify-center">
          Group can have up to <span> {memberCount} members </span>
        </div>
      )}
      {variant === "UserJoinedGroup" && (
        <div className="chatdata-sec flex justify-center">
          {" "}
          <span> {name} </span> joined the group
        </div>
      )}
      {variant === "UserLeaveGroup" && (
        <div className="chatdata-sec flex justify-center">
          <span> {name} </span> leave the group
        </div>
      )}
      {/* {variant === "ChannelCreated" && (
        <div className="chatdata-sec flex justify-center">
          <span> {name} </span> created the <span> {channelName} </span>channel
        </div>
      )} */}
      {/* {variant === "UserJoinedChannel" && (
        <div className="chatdata-sec flex justify-center">
          <span> {name} </span> joined the channel
        </div>
      )} */}

      {/* {variant === "UserLeaveChannel" && (
        <div className="chatdata-sec flex justify-center">
          <span> {name} </span> leave channel
        </div>
      )} */}
      {/* {variant === "UserRemovedFromChannel" && (
        <div className="chatdata-sec flex justify-center">
          <span> {name} </span> removed <span> {userName} </span> from channel
        </div>
      )} */}
      {variant === "UserRemovedFromGroup" && (
        <div className="chatdata-sec flex justify-center">
          <span> {name} </span> removed <span> {userName} </span> from group
        </div>
      )}
      {variant === "EditedGroupName" && (
        <div className="chatdata-sec flex justify-center">
          <span> {name} </span> edited group name from <span>{oldName}</span> to{" "}
          <span>{newName}</span>
        </div>
      )}
      {/* {variant === "EditedChannelName" && (
        <div className="chatdata-sec flex justify-center">
          <span> {name} </span> edited channel name from <span>{oldName}</span>{" "}
          to <span>{newName}</span>
        </div>
      )} */}
      {variant === "ChangedGroupImage" && (
        <div className="chatdata-sec flex justify-center">
          <span> {name} </span> changed group image
        </div>
      )}
      {variant === "ChangedChanneImage" && (
        <div className="chatdata-sec flex justify-center">
          <span> {name} </span> changes channel image
        </div>
      )}
    </>
  );
};
ChatData.propTypes = {
  variant: PropTypes.oneOf([
    "Date",
    "GroupCreated",
    "GroupLimit",
    "UserJoinedGroup",
    "UserLeaveGroup",
    "ChannelCreated",
    "UserJoinedChannel",
    "UserLeaveChannel",
    "UserRemovedFromChannel",
    "UserRemovedFromGroup",
    "EditedGroupName",
    "EditedChannelName",
    "ChangedGroupImage",
    "ChangedChanneImage",
  ]),
  date: PropTypes.string,
  name: PropTypes.string,
  groupName: PropTypes.string,
  channelName: PropTypes.string,
  memberCount: PropTypes.number,
};
export default ChatData;
